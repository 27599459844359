import { Label } from "../ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";

interface SelectProps {
  htmlFor: string;
  label: string;
  onChange: (value: string) => void;
  defaultValue?: string;
  options: { value: string; label: string }[];
}

const DropdownList = ({
  htmlFor,
  label,
  options,
  defaultValue,
  onChange,
}: SelectProps) => {
  return (
    <div className="flex flex-col gap-2">
      <Label htmlFor={htmlFor}>{label}</Label>
      <Select onValueChange={onChange} defaultValue={defaultValue}>
        <SelectTrigger className="ml-1">
          <SelectValue placeholder="" />
        </SelectTrigger>
        <SelectContent>
          {options.map((option) => (
            <SelectItem value={option.value} key={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};

export default DropdownList;
