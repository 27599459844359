import { queryClient } from "@/config/queryClient";
import Root from "./root";
import { createRootRouteWithContext } from "@tanstack/react-router";
import NotFound from "./NotFound";

export const rootRoute = createRootRouteWithContext<{
  queryClient: typeof queryClient;
}>()({
  component: Root,
  notFoundComponent: NotFound,
});
