import { RouterProvider } from "@tanstack/react-router";
import router from "./routes/router";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./config/queryClient";
import { Toaster } from "react-hot-toast";
import { usePushManager } from "./hooks/usePushManager";
import { useRegisterSW } from "virtual:pwa-register/react";
import IosInstallModal from "./components/common/IosInstallModal";
import { useSentry } from "./config/sentry";
import { useInitializeUser } from "./hooks/useUser";

function App() {
  useInitializeUser();

  useRegisterSW({
    immediate: true,
  });

  usePushManager();

  useSentry();

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <Toaster />
      <IosInstallModal />
    </QueryClientProvider>
  );
}

export default App;
