import { supabase } from "@/config/supabase";
import dayjs from "dayjs";

export const getAllConnections = async () => {
  const today = dayjs().format("YYYY-MM-DD HH:mm:ss");

  const connections = await supabase
    .from("connections")
    .select()
    .gt("expires_at", today);

  return connections.data;
};
