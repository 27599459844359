import { HttpClient } from "./http";

export const getDiagnostics = async (
  httpClient: HttpClient,
  installationId: number,
  token: string
) => {
  const diagnosticsResult = await httpClient.get(
    `/v2/installations/${installationId}/diagnostics`,
    token
  );

  const diagnostics = (await diagnosticsResult.json()) as {
    success: boolean;
    records: {
      instance: string;
      code: string;
      rawValue: string;
    }[];
  };

  return diagnostics;
};
