import { getDiagnostics } from "./diagnostics";
import { HttpClient } from "./http";

export const getCurrentStateOfCharge = async (
  httpClient: HttpClient,
  installationId: number,
  token: string
) => {
  const diagnostics = await getDiagnostics(httpClient, installationId, token);

  const socDiagnostics = diagnostics.records.find(
    (record) => record.code === "SOC"
  );

  const rawValue = socDiagnostics?.rawValue ?? null;
  if (rawValue === null) {
    throw new Error("SOC not found");
  }

  return parseFloat(rawValue);
};
