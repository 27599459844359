import { supabase } from "@/config/supabase";

export const getOwner = async () => {
  const owner = await supabase.from("owners").select("*").maybeSingle();

  if (owner.error) {
    throw new Error(owner.error.message);
  }

  return owner.data;
};
