import { z } from "zod";

export enum AuthenticationState {
  Login = "Login",
  Code = "Code",
}

export const CodeFormSchema = z.object({
  code: z
    .string()
    .length(6, "Code must be 6 digits")
    .refine((code) => {
      return /^\d+$/.test(code);
    }, "Code must be a number"),
});

export type ICodeForm = z.infer<typeof CodeFormSchema>;

export const LoginFormSchema = z.object({
  email: z.string().email(),
});

export type ILoginForm = z.infer<typeof LoginFormSchema>;
