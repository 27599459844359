import { supabase } from "@/config/supabase";
import { askPermission, canReceivePushNotifications } from "@/utils/push";
import { useEffect } from "react";
import useStore from "@/store/store";

const urlBase64ToUint8Array = (base64String: string) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

const subscribe = async (
  userId: string,
  serviceWorkerRegistration: ServiceWorkerRegistration
) => {
  const subscribeOptions = {
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(
      import.meta.env.VITE_VAPID_APPLICATION_KEY
    ),
  };

  const subscription = await serviceWorkerRegistration.pushManager.subscribe(
    subscribeOptions
  );

  const { keys, endpoint } = subscription.toJSON();

  if (keys && endpoint) {
    await supabase.from("push_subscriptions").insert({
      keys,
      endpoint,
      user_id: userId,
    });
  }
};

export const useIosInstallModal = () => {
  const localStorageKey = "SHOW_IOS_INSTALL_MODAL";

  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  };

  // check if the device is in standalone mode
  const isInStandaloneMode = () => {
    return "standalone" in window.navigator && window.navigator.standalone;
  };

  // show the modal only once
  const localStorageKeyValue = localStorage.getItem(localStorageKey);
  const iosInstallModalShown = localStorageKeyValue
    ? JSON.parse(localStorageKeyValue)
    : false;
  const shouldShowModalResponse =
    isIos() && !isInStandaloneMode() && !iosInstallModalShown;
  if (shouldShowModalResponse) {
    localStorage.setItem(localStorageKey, "true");
  }

  return shouldShowModalResponse;
};

export const usePushManager = () => {
  const { user } = useStore();

  useEffect(() => {
    (async () => {
      if (canReceivePushNotifications()) {
        const registrations = await navigator.serviceWorker.getRegistrations();

        if (user && registrations[0]) {
          const userId = user.id;
          const existingSubscriptions = await supabase
            .from("push_subscriptions")
            .select("*");

          if (!existingSubscriptions.data?.length) {
            const permission = await askPermission();

            if (permission === "granted") {
              await subscribe(userId, registrations[0]);
            }
          }
        }
      }
    })().catch();
  }, [user]);
};
