import { api } from "@/config/api";

export const createUser = async (accessToken: string, email: string) => {
  const result = await fetch(`${api.baseUrl}/users`, {
    method: "POST",
    body: JSON.stringify({
      email,
    }),
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${accessToken}`,
    },
  });

  const json = await result.json();

  if (!result.ok) {
    throw new Error(JSON.stringify(json));
  }

  return json;
};
