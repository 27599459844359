import { Route, redirect } from "@tanstack/react-router";

import { getSession } from "@/services/auth/getSession";
import { rootRoute } from "../rootRoute";
import { getOwner } from "@/services/queries/owners/getOwner";

export const isOwnerMiddleware = new Route({
  getParentRoute: () => rootRoute,
  id: "is-owner-middleware",
  loader: async ({ context: { queryClient } }) => {
    const session = await queryClient.ensureQueryData({
      queryKey: ["session"],
      queryFn: getSession,
    });

    if (!session) {
      throw redirect({
        to: "/login",
      });
    }

    const owner = await queryClient.ensureQueryData({
      queryKey: ["owner"],
      queryFn: getOwner,
    });

    if (!owner) {
      throw redirect({
        to: "/login",
      });
    }
  },
});
