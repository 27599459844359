import { z } from "zod";
import { Mapper } from "./mapper.interface";
import { DashboardRaw, WidgetConfigSchema } from "@battery-monitor/types";

const DashboardSchema = z.object({
  compositeId: z.string(),
  config: z.record(z.unknown()),
  id: z.number(),
  name: z.string(),
  provider: z.union([z.literal("ixon"), z.literal("vrm")]),
  userId: z.string(),
  widgets: WidgetConfigSchema,
  createdAt: z.string(),
});

export type Dashboard = z.infer<typeof DashboardSchema>;

class DashboardMapper implements Mapper<DashboardRaw, Dashboard> {
  public mapFrom(params: DashboardRaw) {
    const dashboard = DashboardSchema.parse({
      compositeId: params.composite_id,
      config: params.config,
      id: params.id,
      name: params.name,
      provider: params.provider,
      userId: params.user_id,
      widgets: params.widgets,
      createdAt: params.created_at,
    });
    return dashboard;
  }

  public mapTo(params: Dashboard) {
    return {
      composite_id: params.compositeId,
      config: JSON.parse(JSON.stringify(params.config)),
      created_at: params.createdAt,
      id: params.id,
      name: params.name,
      provider: params.provider,
      user_id: params.userId,
      widgets: params.widgets,
    };
  }
}

export const dashboardMapper = new DashboardMapper();
