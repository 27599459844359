import { Link, Route, useNavigate } from "@tanstack/react-router";
import { useQuery } from "@tanstack/react-query";
import { getAllDashboardsForUser } from "@/services/queries/dashboards/getAllDashboards";
import { dashboardRootRoute } from "./root";
import InstallationsListItem from "@/components/installations/InstallationsListItem";
import { supabase } from "@/config/supabase";
import PageContainer from "@/components/common/PageContainer";
import { getOwner } from "@/services/queries/owners/getOwner";
import { Button } from "@/components/ui/button";

const queryOptions = (userId: string) => ({
  queryKey: ["dashboards", userId],
  queryFn: () => getAllDashboardsForUser(userId),
});

const dashboardIndexRoute = new Route({
  getParentRoute: () => dashboardRootRoute,
  path: "/",
  loader: async ({ context: { queryClient } }) => {
    const user = await supabase.auth.getUser();

    await queryClient.ensureQueryData({
      queryKey: ["owner"],
      queryFn: getOwner,
    });
    await queryClient.ensureQueryData(queryOptions(user.data.user?.id ?? ""));
  },
  component: DashboardIndexRoute,
});

function DashboardIndexRoute() {
  const userQueryResult = useQuery({
    queryKey: ["user"],
    queryFn: async () => {
      const user = await supabase.auth.getUser();
      if (user.error) {
        throw new Error(user.error.message);
      }

      return user.data.user;
    },
  });

  const ownerQueryResult = useQuery({
    queryKey: ["owner"],
    queryFn: getOwner,
  });

  const dashboardsQueryResult = useQuery({
    enabled: !!userQueryResult.data,
    queryKey: ["dashboards", userQueryResult.data?.id],
    queryFn: () => getAllDashboardsForUser(userQueryResult.data?.id ?? ""),
  });

  const navigation = useNavigate({
    from: dashboardIndexRoute.id,
  });

  return (
    <PageContainer className="p-50 justify-center items-center">
      <div className="flex flex-col items-center w-5/6 md:w-1/2 grow">
        <h1 className="self-start text-3xl text-black font-semibold mb-10">
          Dashboards
        </h1>
        {!dashboardsQueryResult.data?.length && (
          <>
            <p className="text-black text-center pb-2">
              We see that there are no dashboards here yet.
            </p>
            {ownerQueryResult.data ? (
              <>
                <p className="text-black text-center pb-20">
                  You can create one by pressing the button below.
                </p>
                <div className="flex flex-col justify-center">
                  <Link to="/admin/dashboard">
                    <Button className="text-md" variant="link">
                      Create a dashboard
                    </Button>
                  </Link>
                </div>
              </>
            ) : (
              <p className="text-black text-center pb-20">
                Ask your contact person to create one for you
              </p>
            )}
          </>
        )}

        <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4">
          {dashboardsQueryResult.data?.map((dashboard) => (
            <InstallationsListItem
              key={dashboard.id}
              dashboard={dashboard}
              onClick={() =>
                navigation({
                  to: "/dashboard/$id",
                  params: {
                    id: dashboard.id.toString(),
                  },
                })
              }
            />
          ))}
        </div>
      </div>
    </PageContainer>
  );
}

export default dashboardIndexRoute;
