import { StateCreator } from "zustand";
import { Subscription, User } from "@supabase/supabase-js";

// Define the shape of your store's state
export interface UserSlice {
  user: User | null;
  setUser: (user: User | null) => void;
  subscription: Subscription | null;
  setSubscription: (subscription: Subscription | null) => void;
}

// Create the store
const createUserSlice: StateCreator<UserSlice, [], [], UserSlice> = (set) => ({
  user: null,
  setUser: (user) => set({ user }),
  subscription: null,
  setSubscription: (subscription) => set({ subscription }),
});

export default createUserSlice;
