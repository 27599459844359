import { Series } from "./chart.interface";
import * as d3 from "d3";

export const getMaxValueInObject = (obj: Series) =>
  Math.max(
    ...Object.entries(obj).map(([key, value]) =>
      key === "date" ? -Infinity : value
    )
  );

export const getMinValueInObject = (obj: Series) =>
  Math.min(
    ...Object.entries(obj).map(([key, value]) =>
      key === "date" ? Infinity : value
    )
  );

export const getAxisYDomain = (
  data: Series[],
  from: number | null,
  to: number | null
): [number, number, number, number] => {
  const start = from === null ? Math.min(...data.map((d) => d.date)) : from;
  const end = to === null ? Math.max(...data.map((d) => d.date)) : to;

  const { indexFrom, indexTo } = data.reduce(
    ({ indexFrom, resValFrom, indexTo, resValTo }, { date }, index) => {
      if (Math.abs(start - date) < Math.abs(start - resValFrom)) {
        indexFrom = index;
        resValFrom = date;
      }
      if (Math.abs(end - date) < Math.abs(end - resValTo)) {
        indexTo = index;
        resValTo = date;
      }
      return { indexFrom, resValFrom, indexTo, resValTo };
    },
    { indexFrom: 0, resValFrom: 0, indexTo: 0, resValTo: 0 }
  );

  const refData = data.slice(indexFrom, indexTo + 1);

  let [bottom, top] = [
    getMinValueInObject(refData[0]),
    getMaxValueInObject(refData[0]),
  ];

  refData.forEach((d) => {
    const min = getMinValueInObject(d);
    const max = getMaxValueInObject(d);

    if (max > top) top = max;
    if (min < bottom) bottom = min;
  });

  return [bottom, top, start, end];
};

const formatMillisecond = d3.timeFormat(".%L");
const formatSecond = d3.timeFormat(":%S");
const formatMinute = d3.timeFormat("%I:%M");
const formatHour = d3.timeFormat("%H:%M");
const formatDay = d3.timeFormat("%d-%m-%y");
const formatMonth = d3.timeFormat("%d-%m-%y");
const formatYear = d3.timeFormat("%d-%m-%y");

export const multiFormatDate = (date: number) => {
  const format =
    new Date(date).getMilliseconds() !== 0
      ? formatMillisecond
      : new Date(date).getSeconds() !== 0
      ? formatSecond
      : new Date(date).getMinutes() !== 0
      ? formatMinute
      : new Date(date).getHours() !== 0
      ? formatHour
      : new Date(date).getDay() !== 0
      ? formatDay
      : new Date(date).getMonth() !== 0
      ? formatMonth
      : formatYear;

  return format(new Date(date));
};
