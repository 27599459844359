import { api } from "@/config/api";

export const deleteUser = async (accessToken: string, userId: string) => {
  const result = await fetch(`${api.baseUrl}/users`, {
    method: "DELETE",
    body: JSON.stringify({
      userId,
    }),
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${accessToken}`,
    },
  });

  const json = await result.json();

  if (!result.ok) {
    throw new Error(JSON.stringify(json));
  }

  return json;
};
