import { type ZodObject, type ZodRawShape, z } from 'zod';

export interface VrmResponse<T> {
  success: boolean;
  records: T[];
}

const createVrmResponseSchema = <T extends ZodRawShape>(obj: ZodObject<T>) =>
  z.object({
    success: z.boolean(),
    records: z.array(obj),
  });

export const InstallationSchema = z.object({
  idSite: z.number(),
  name: z.string(),
  identifier: z.string(),
  device_icon: z.string(),
});

export const InstallationResponseSchema =
  createVrmResponseSchema(InstallationSchema);

export type Installation = z.infer<typeof InstallationSchema>;
