import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ILoginForm, LoginFormSchema } from "./AuthenticationForms.schema";

interface LoginFormProps {
  onSubmit: (form: ILoginForm) => void;
}

const LoginForm = ({ onSubmit }: LoginFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginForm>({
    defaultValues: { email: "" },
    resolver: zodResolver(LoginFormSchema),
  });

  const submit = handleSubmit((data) => onSubmit(data));

  return (
    <form onSubmit={submit} className="w-full">
      <div className="mb-4">
        <Label htmlFor="email" className="text-md">
          Email
        </Label>
        <Input
          {...register("email")}
          type="email"
          className="mt-1 text-md"
          placeholder="you@example.com"
        />
        {errors.email && (
          <p className="text-red-500 text-sm mt-1">{errors.email.message}</p>
        )}
      </div>
      <button
        type="submit"
        className="w-full bg-indigo-600 hover:bg-indigo-700 text-white p-3 rounded-lg font-semibold transition duration-300"
      >
        Login
      </button>
    </form>
  );
};

export default LoginForm;
