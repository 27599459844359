interface IxonCompaniesResults {
  type: "Company";
  data: {
    publicId: string;
    name: string;
  }[];
  moreAfter: null;
  status: "success" | "failed" | "error";
}

export const getCompanies = async (token: string) => {
  const res = await fetch(
    "https://portal.ixon.cloud/api/companies?fields=publicId,name",
    {
      headers: {
        "Api-Version": "2",
        "Api-Application": "9J9IZzeT4xN4",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      method: "GET",
    }
  );

  const json = (await res.json()) as IxonCompaniesResults;

  return json;
};

interface IxonAgentsResults {
  type: "Agent";
  data: {
    publicId: string;
    name: string;
    deviceId: string;
  }[];
  moreAfter: null;
  status: "success" | "failed";
}

export const getAgents = async (token: string, companyId: string) => {
  const res = await fetch(
    "https://portal.ixon.cloud/api/agents?fields=publicId,name,deviceId",
    {
      headers: {
        "Api-Version": "2",
        "Api-Application": "9J9IZzeT4xN4",
        "Content-Type": "application/json",
        "Api-Company": companyId,
        Authorization: `Bearer ${token}`,
      },
      method: "GET",
    }
  );

  const json = (await res.json()) as IxonAgentsResults;

  return json;
};

interface IxonDataSourcesResults {
  type: "AgentDataSource";
  data: {
    publicId: string;
    name: string;
  }[];
  moreAfter: null;
  status: "success" | "failed";
}

export const getDataSources = async (
  token: string,
  {
    agentId,
    companyId,
  }: {
    agentId: string;
    companyId: string;
  }
) => {
  const res = await fetch(
    `https://portal.ixon.cloud/api/agents/${agentId}/data-sources`,
    {
      headers: {
        "Api-Version": "2",
        "Api-Application": "9J9IZzeT4xN4",
        "Content-Type": "application/json",
        "Api-Company": companyId,
        Authorization: `Bearer ${token}`,
      },
      method: "GET",
    }
  );

  const json = (await res.json()) as IxonDataSourcesResults;

  return json;
};
