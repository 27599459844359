export const canReceivePushNotifications = () => {
  if (!("serviceWorker" in navigator)) {
    // Service Worker isn't supported on this browser, disable or hide UI.
    return false;
  }

  if (!("PushManager" in window)) {
    // Push isn't supported on this browser, disable or hide UI.
    return false;
  }

  return true;
};

export const askPermission = async () => {
  try {
    const permissionResult_1 = await new Promise<NotificationPermission>(
      function (resolve, reject) {
        const permissionResult = Notification.requestPermission(function (
          result
        ) {
          resolve(result);
        });

        if (permissionResult) {
          permissionResult.then(resolve, reject);
        }
      }
    );

    return permissionResult_1;
  } catch (error) {
    return "denied";
  }
};
