import type { VFC } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Dashboard } from "@battery-monitor/mappers";
import { IoBatteryCharging } from "react-icons/io5";
import { useQuery } from "@tanstack/react-query";
import { getDashboard } from "@/services/queries/dashboards/getDashboard";
import { getProvider } from "@/services/queries/connections/getProvider";
import { Skeleton } from "../ui/skeleton";
import { getBatteryState } from "@/services/providers";

interface InstallationsListItemProps {
  dashboard: Dashboard;
  onClick: (id: string) => void;
}

const queryOptions = (id: number) => ({
  queryKey: ["dashboards", id],
  queryFn: () => getDashboard(id),
});

const InstallationsListItem: VFC<InstallationsListItemProps> = ({
  dashboard,
  onClick,
}) => {
  const dashboardQueryResult = useQuery(queryOptions(dashboard.id));

  const connection = useQuery({
    enabled: !!dashboardQueryResult.data?.provider,
    queryKey: ["connections", dashboardQueryResult.data?.provider],
    queryFn: () =>
      getProvider(dashboardQueryResult.data!.provider as "ixon" | "vrm"),
  });

  const token = connection.data?.data?.token;

  const batteryStateQueryResult = useQuery({
    enabled: !!token && !!dashboardQueryResult.data,
    queryKey: ["chartData", dashboardQueryResult.data?.config],
    queryFn: () => {
      if (!token || !dashboardQueryResult.data) {
        throw new Error("No token or dashboard");
      }
      return getBatteryState(dashboardQueryResult.data, token);
    },
  });

  return (
    <Card
      className="w-full hover:bg-gray-300 cursor-pointer shadow-lg md:py-5 md:px-5 flex flex-col justify-between"
      onClick={() => onClick(dashboard.id.toString())}
    >
      <CardHeader>
        <CardTitle className="text-lg">{dashboard.name}</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex flex-col md:flex-row sm:gap-2 justify-between">
          {batteryStateQueryResult.isLoading ? (
            <Skeleton className="h-6 w-full" />
          ) : (
            <div className="flex flex-row items-center gap-1">
              <p className="text-gray-600">
                {batteryStateQueryResult.data?.toFixed(1)} %
              </p>
              <IoBatteryCharging size={25} />
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default InstallationsListItem;
