import { supabase } from "@/config/supabase";

export const handleSignIn = async ({ email }: { email: string }) => {
  const result = await supabase.auth.signInWithOtp({
    email,
  });

  return result;
};

export const handleCode = async ({
  code,
  email,
}: {
  code: string;
  email: string;
}) => {
  const result = await supabase.auth.verifyOtp({
    email,
    token: code,
    type: "email",
  });

  return result;
};
