import classNames from "classnames";
import { PropsWithChildren } from "react";
import { ScrollArea } from "../ui/scroll-area";

const PageContainer = ({
  className,
  children,
}: PropsWithChildren<{ className?: string }>) => {
  return (
    <div
      className={classNames("flex flex-col bg-gray-900 w-full grow", className)}
    >
      <div className="flex grow flex-col items-center justify-center bg-gray-900 w-full max-h-[calc(100%-45px)] grow">
        <ScrollArea className="bg-white p-4 md:p-8 rounded-md w-[87%] md:w-5/6 grow my-2 md:my-16">
          <div className="flex flex-col items-center grow">{children}</div>
        </ScrollArea>
      </div>
    </div>
  );
};

export default PageContainer;
