import { z } from "zod";

export const ProvderAuthenticationSchema = z.object({
  username: z.string().min(1, "Username is required"),
  password: z.string().min(1, "Password is required"),
  twoFactorCode: z.string(),
});

export type IProviderAuthentication = z.infer<
  typeof ProvderAuthenticationSchema
>;
