import { StateCreator } from "zustand";

export interface SideBarSlice {
  sideBarOpen: boolean;
  toggleSideBarOpen: () => void;
}

const createSideBarSlice: StateCreator<SideBarSlice, [], [], SideBarSlice> = (
  set
) => ({
  sideBarOpen: false,
  toggleSideBarOpen: () =>
    set((state) => ({ sideBarOpen: !state.sideBarOpen })),
});

export default createSideBarSlice;
