import dayjs from "dayjs";
import { z } from "zod";

export const DashboardSettingsSchema = z.object({
  chartDates: z.object({
    from: z.instanceof(dayjs as unknown as typeof dayjs.Dayjs),
    to: z.instanceof(dayjs as unknown as typeof dayjs.Dayjs),
  }),
  startFromZero: z.boolean(),
});

export type DashboardSettings = z.infer<typeof DashboardSettingsSchema>;
