import { Profile } from "@battery-monitor/types";

interface SelectUserProps {
  users: Profile[];
  onSelect: (user: Profile) => void;
}

const SelectUser = ({ users, onSelect }: SelectUserProps) => {
  return (
    <>
      <h1 className="text-3xl text-black font-semibold mb-10">Select a user</h1>
      <p className="text-black pb-10">
        Choose a user to create a dashboard for.
      </p>
      <div className="flex flex-col gap-2">
        {users.map((user) => (
          <div
            className="p-2 rounded border border-slate-500 hover:bg-slate-100 cursor-pointer"
            onClick={() => onSelect(user)}
            key={user.id}
          >
            <p className="text-black">{user.email}</p>
          </div>
        ))}
      </div>
    </>
  );
};

export default SelectUser;
