import { createDashboard } from "@/services/queries/dashboards/createDashboard";
import { IxonProviderClient } from "@battery-monitor/providers";
import { Profile, Widget } from "@battery-monitor/types";

interface Installation {
  id: string;
  name: string;
  config?: unknown;
}

export const getConfigForVrm = (selectedInstallation: Installation) => {
  return {
    config: {
      installationId: selectedInstallation.id,
    },
    compositeId: selectedInstallation.id,
  };
};

export const getConfigForIxon = async (
  selectedInstallation: Installation,
  token: string
) => {
  if (!token) {
    return { config: null, compositeId: "" };
  }

  const client = new IxonProviderClient(token);
  const installationConfig = selectedInstallation.config as {
    agentId: string;
    companyId: string;
  };

  const dataSourceResult = await client.getDataSources({
    agentId: installationConfig.agentId,
    companyId: installationConfig.companyId,
  });

  return {
    config: {
      agentId: installationConfig.agentId,
      companyId: installationConfig.companyId,
      datasourceId: dataSourceResult.data[0].publicId,
    },
    compositeId:
      installationConfig.agentId +
      installationConfig.companyId +
      dataSourceResult.data[0].publicId,
  };
};

export const createDashboardData = async ({
  selectedProvider,
  selectedUser,
  selectedInstallation,
  data,
  config,
  compositeId,
}: {
  selectedProvider: "ixon" | "vrm";
  selectedUser: Profile;
  selectedInstallation: Installation;
  data: {
    [key in Widget]: {
      visible: boolean;
      components: { code: string; visible: boolean }[];
    };
  };
  config: { [key: string]: unknown };
  compositeId: string;
}) => {
  const error = await createDashboard({
    provider: selectedProvider,
    userId: selectedUser.id,
    widgets: {
      ...(data.outputPower.visible && {
        outputPower: {
          code: "outputPower",
          components: data.outputPower.components,
        },
      }),
      ...(data.inputPower.visible && {
        inputPower: {
          code: "inputPower",
          components: data.inputPower.components,
        },
      }),
      ...(data.batterySoc.visible && {
        batterySoc: {
          code: "batterySoc",
          components: data.batterySoc.components,
        },
      }),
      ...(data.inputVoltage.visible && {
        inputVoltage: {
          code: "inputVoltage",
          components: data.inputVoltage.components,
        },
      }),
    },
    config,
    name: selectedInstallation.name,
    compositeId,
  });

  if (error) {
    throw new Error(error.code);
  }
};
