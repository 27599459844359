import { create } from "zustand";
import createSideBarSlice, { SideBarSlice } from "./sidebar";
import createUserSlice, { UserSlice } from "./user";
import createDashboardSlice, { DashboardSlice } from "./dashboard";

const useStore = create<SideBarSlice & UserSlice & DashboardSlice>()(
  (...a) => ({
    ...createSideBarSlice(...a),
    ...createUserSlice(...a),
    ...createDashboardSlice(...a),
  })
);

export default useStore;
