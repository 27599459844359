import { Dayjs } from "dayjs";

interface Params {
  companyId: string;
  token: string;
  limit: number;
  datasourceId: string;
  codes: string[];
  dates: {
    from: Dayjs;
    to: Dayjs;
  };
}

interface DataPoint {
  points: {
    time: string;
    values: {
      "load-power": number;
      "input-power-charge": number;
      "input-power-cee": number;
      "external-power-meter": number;
      "selected-soc": number;
      "input-voltage-1": number;
      "input-voltage-2": number;
      "input-voltage-3": number;
    };
  }[];
}

interface IxonDataTag {
  slug: string;
  preAggr: string;
  queries: {
    ref: string;
    limit: number;
    decimals: number;
    postAggr: string;
  }[];
}

interface IxonDataResults {
  data: [DataPoint];
}

const widgetTags = (limit: number): { [key: string]: IxonDataTag[] } => ({
  outputPower: [
    {
      slug: "real-power-load",
      preAggr: "raw",
      queries: [
        {
          ref: "load-power",
          decimals: 1,
          limit,
          postAggr: "last",
        },
      ],
    },
  ],
  inputPower: [
    {
      slug: "real-power-fast-charge",
      preAggr: "raw",
      queries: [
        {
          ref: "input-power-charge",
          decimals: 1,
          limit,
          postAggr: "last",
        },
      ],
    },
    {
      slug: "chg-real-power-from-cee-125-a",
      preAggr: "raw",
      queries: [
        {
          ref: "input-power-cee",
          decimals: 1,
          limit,
          postAggr: "last",
        },
      ],
    },
    {
      slug: "external-power-meter",
      preAggr: "raw",
      queries: [
        {
          ref: "external-power-meter",
          decimals: 1,
          limit,
          postAggr: "last",
        },
      ],
    },
  ],
  batterySoc: [
    {
      slug: "selected-soc",
      preAggr: "raw",
      queries: [
        {
          ref: "selected-soc",
          limit,
          decimals: 1,
          postAggr: "last",
        },
      ],
    },
  ],
  inputVoltage: [
    {
      slug: "125-a-input-voltage-l-1",
      preAggr: "raw",
      queries: [
        {
          ref: "input-voltage-1",
          limit,
          decimals: 1,
          postAggr: "last",
        },
      ],
    },
    {
      slug: "125-a-input-voltage-l-2",
      preAggr: "raw",
      queries: [
        {
          ref: "input-voltage-2",
          limit,
          decimals: 1,
          postAggr: "last",
        },
      ],
    },
    {
      slug: "125-a-input-voltage-l-3",
      preAggr: "raw",
      queries: [
        {
          ref: "input-voltage-3",
          limit,
          decimals: 1,
          postAggr: "last",
        },
      ],
    },
  ],
});

export const getData = async ({
  companyId,
  token,
  datasourceId,
  codes,
  limit,
  dates: { from, to },
}: Params) => {
  const tags = codes
    .reduce<IxonDataTag[]>((list, code) => {
      const tags =
        widgetTags(limit)[code] ??
        Object.values(widgetTags(limit)).find((tags) =>
          tags.find((tag) => tag.queries[0].ref === code)
        );
      list = [...list, ...tags];
      return list;
    }, [])
    .reduce<IxonDataTag[]>((list, tag) => {
      if (list.find((t) => t.slug === tag.slug)) {
        return list;
      } else {
        return [...list, tag];
      }
    }, []);

  const body = [
    {
      end: `${to.toISOString().slice(0, -5)}Z`,
      start: `${from.toISOString().slice(0, -5)}Z`,
      source: { publicId: datasourceId },
      tags,
    },
  ];

  const dataResponse = await fetch("https://portal.ixon.cloud:443/api/data", {
    method: "POST",
    headers: {
      "Api-Version": "2",
      "Api-Application": "9J9IZzeT4xN4",
      "Api-Company": companyId,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });

  const userJson = (await dataResponse.json()) as IxonDataResults;

  return userJson;
};
