import { supabase } from "@/config/supabase";
import { Dashboard } from "@battery-monitor/mappers";

export const createDashboard = async (
  values: Omit<Dashboard, "id" | "createdAt">
) => {
  const { error } = await supabase.from("dashboards").insert({
    composite_id: values.compositeId,
    config: JSON.parse(JSON.stringify(values.config)),
    name: values.name,
    provider: values.provider,
    user_id: values.userId,
    widgets: values.widgets,
  });

  return error;
};
