import { WidgetCollection } from "@battery-monitor/types";

export const widgetCollection = {
  outputPower: {
    description: "Output Power",
    code: "outputPower",
    components: [
      {
        description: "Load Power",
        code: "load-power",
      },
    ],
  },
  inputPower: {
    description: "Input Power",
    code: "inputPower",
    components: [
      {
        description: "Fastcharge Input",
        code: "input-power-charge",
      },
      {
        description: "125A Input",
        code: "input-power-cee",
      },
      {
        description: "External Power Meter",
        code: "external-power-meter",
      },
    ],
  },
  batterySoc: {
    description: "Battery SOC (State of Charge)",
    code: "batterySoc",
    components: [
      {
        description: "SOC",
        code: "selected-soc",
      },
    ],
  },
  inputVoltage: {
    description: "Input Voltage",
    code: "inputVoltage",
    components: [
      {
        description: "Input Voltage L1",
        code: "input-voltage-1",
      },
      {
        description: "Input Voltage L2",
        code: "input-voltage-2",
      },
      {
        description: "Input Voltage L3",
        code: "input-voltage-3",
      },
    ],
  },
} satisfies WidgetCollection;
