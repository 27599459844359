import {
  LineChart as RechartsLineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceArea,
  ResponsiveContainer,
  Legend,
} from "recharts";
import React from "react";
import { useState } from "react";
import { Button } from "../ui/button";
import * as d3 from "d3";
import ChartTooltip from "./ChartTooltip";
import { getAxisYDomain, multiFormatDate } from "./helpers";
import { Payload } from "recharts/types/component/DefaultLegendContent";
import { ChartData, Widget } from "@battery-monitor/types";
import { transformData } from "@/utils/transformChartData";
import { useDashboard } from "@/hooks/useDashboard";

const colorByIndex: { [key: string]: string } = {
  "0": "green",
  "1": "red",
  "2": "blue",
  "3": "orange",
};

interface LineChartProps {
  data: ChartData<Widget>;
  name: string;
  unit: string;
  startFromZero: boolean;
}
interface RefAreaState {
  refAreaLeft: number | null;
  refAreaRight: number | null;
}

const LineChart: React.FC<LineChartProps> = ({ name, data: rawData, unit }) => {
  const data = transformData(rawData);

  const {
    settings: { startFromZero },
    zoom,
    zoomProps,
  } = useDashboard();

  const defaultRefAreaState: RefAreaState = {
    refAreaLeft: null,
    refAreaRight: null,
  };

  const series = data.length
    ? Object.keys(data[0]).reduce<string[]>((keys, key) => {
        if (key !== "date") {
          return [...keys, key];
        }
        return keys;
      }, [])
    : [];

  const [refArea, setRefArea] = useState<RefAreaState>(defaultRefAreaState);
  const [activeSeries, setActiveSeries] = useState<string[]>(series);

  const resetChartToDefault = () => setRefArea(defaultRefAreaState);

  const handleZoomEnd = () => {
    let zoomLeft = refArea.refAreaLeft;
    let zoomRight = refArea.refAreaRight;

    if (zoomLeft === zoomRight || zoomRight === null || zoomLeft === null) {
      resetChartToDefault();
      return zoom([null, null]);
    }

    // xAxis domain
    if (zoomLeft !== null && zoomLeft > zoomRight) {
      [zoomLeft, zoomRight] = [zoomRight, zoomLeft];
    }

    resetChartToDefault();
    return zoom([zoomLeft, zoomRight]);
  };

  const handleLegendClick = (data: Payload) => {
    const dataKey = data.dataKey;
    if (dataKey && typeof dataKey === "string") {
      if (activeSeries.includes(dataKey)) {
        setActiveSeries((prev) => prev.filter((key) => key !== dataKey));
      } else {
        setActiveSeries((prev) => [...prev, dataKey]);
      }
    }
  };

  const ticks = data.length
    ? d3
        .scaleTime()
        .domain([data[0].date, data[data.length - 1].date])
        .nice()
        .ticks(4)
        .map((tick) => tick.valueOf())
    : [];

  const [bottom, top, start, end] = getAxisYDomain(
    data,
    zoomProps[0],
    zoomProps[1]
  );

  return (
    <div className="overflow-hidden bg-white w-full flex items-center flex-col">
      <div className="p-2 w-full mx-auto">
        <div className="bg-white w-full mx-auto m-1 rounded-lg shadow-md">
          <div className="bg-gray-900 text-white text-center text-xl font-semibold mb-2 py-2 rounded-t-lg shadow-sm">
            {name}
          </div>
          <div className="relative h-80 pr-4">
            <ResponsiveContainer height={300} width="100%">
              <RechartsLineChart
                data={data}
                margin={{ top: 5, right: 15, bottom: 0, left: 0 }}
                onMouseDown={(e) => {
                  if (e?.activeLabel) {
                    setRefArea({
                      ...refArea,
                      refAreaLeft: parseInt(e.activeLabel),
                    });
                  }
                }}
                onMouseMove={(e) =>
                  e?.activeLabel &&
                  refArea.refAreaLeft &&
                  setRefArea({
                    ...refArea,
                    refAreaRight: parseInt(e.activeLabel),
                  })
                }
                onMouseUp={handleZoomEnd}
              >
                <Legend onClick={handleLegendClick} />
                {series.map((serie, index) => (
                  <Line
                    type="linear"
                    dataKey={serie}
                    stroke={colorByIndex[index.toString()]}
                    dot={false}
                    label={serie}
                    key={serie}
                    hide={!activeSeries.includes(serie)}
                  />
                ))}
                <CartesianGrid
                  stroke="black"
                  strokeDasharray="3 3"
                  fill="#f0f0f0"
                  fillOpacity={0.3}
                />
                <XAxis
                  allowDataOverflow
                  dataKey="date"
                  type="number"
                  ticks={ticks}
                  domain={[start, end]}
                  tickFormatter={multiFormatDate}
                />
                <YAxis
                  allowDataOverflow
                  type="number"
                  domain={[startFromZero ? 0 : bottom, top]}
                  tickCount={8}
                  tickFormatter={(tick) => {
                    if (parseInt(tick) > 1000) {
                      return `${(parseInt(tick) / 1000).toFixed(1)}k`;
                    }
                    return tick;
                  }}
                />
                <Tooltip
                  content={<ChartTooltip unit={unit} />}
                  viewBox={{ x: 0, y: 0 }}
                  animationDuration={100}
                />
                {refArea.refAreaLeft && refArea.refAreaRight ? (
                  <ReferenceArea
                    x1={refArea.refAreaLeft}
                    x2={refArea.refAreaRight}
                    strokeOpacity={0.3}
                  />
                ) : null}
              </RechartsLineChart>
            </ResponsiveContainer>
          </div>
          <div className="py-1 flex justify-center">
            <Button
              size="sm"
              type="button"
              onClick={() => {
                zoom([null, null]);
                resetChartToDefault();
              }}
            >
              Reset Zoom
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LineChart;
