import { z } from "zod";

export type Widget =
  | "outputPower"
  | "inputPower"
  | "batterySoc"
  | "inputVoltage";

export const WidgetComponentSchema = z.object({
  code: z.string(),
  visible: z.boolean(),
});

export type WidgetComponent = z.infer<typeof WidgetComponentSchema>;

export const WidgetConfigSchema = z.object({
  outputPower: z
    .object({
      code: z.literal("outputPower"),
      components: z.array(WidgetComponentSchema),
    })
    .optional(),
  inputPower: z
    .object({
      code: z.literal("inputPower"),
      components: z.array(WidgetComponentSchema),
    })
    .optional(),
  batterySoc: z
    .object({
      code: z.literal("batterySoc"),
      components: z.array(WidgetComponentSchema),
    })
    .optional(),
  inputVoltage: z
    .object({
      code: z.literal("inputVoltage"),
      components: z.array(WidgetComponentSchema),
    })
    .optional(),
});

export type WidgetConfig = z.infer<typeof WidgetConfigSchema>;

export interface DataStreamConfiguration {
  description: string;
  code: string;
}

export type WidgetConfiguration<T extends Widget> = DataStreamConfiguration & {
  code: T;
  components: DataStreamConfiguration[];
};

export type WidgetCollection = { [K in Widget]: WidgetConfiguration<K> };
