import { supabase } from "@/config/supabase";
import { dashboardMapper } from "@battery-monitor/mappers";

export const getDashboard = async (id: number) => {
  const result = await supabase
    .from("dashboards")
    .select("*")
    .eq("id", id)
    .single();

  if (result.error) {
    throw new Error(result.error.message);
  }

  return dashboardMapper.mapFrom(result.data);
};
