import { handleSignIn, handleCode } from "@/services/auth/handleLogin";
import { createRoute, redirect, useNavigate } from "@tanstack/react-router";
import { supabase } from "@/config/supabase";
import toast from "react-hot-toast";
import { queryClient } from "@/config/queryClient";
import { rootRoute } from "./rootRoute";
import AuthenticationForm from "@/components/auth/AuthenticationForm";

const LoginRoute = () => {
  const navigate = useNavigate({
    from: loginRoute.id,
  });

  const handleSubmit = async (form: { email: string }) => {
    const result = await handleSignIn(form);

    if (result.error) {
      toast(result.error.message);
      return false;
    }

    return true;
  };

  const handleSubmitCode = async (form: { email: string; code: string }) => {
    const result = await handleCode(form);
    if (result.error) {
      toast(result.error.message);
    } else {
      queryClient.invalidateQueries();
      navigate({
        to: "/dashboard",
      });
    }
  };

  return (
    <div className="grow flex items-center justify-center bg-gray-900 w-full">
      <div className="bg-white p-8 rounded-md shadow-md w-80">
        <h2 className="text-2xl text-black font-semibold text-center mb-6">
          Login
        </h2>
        <AuthenticationForm
          onSubmit={handleSubmit}
          onSubmitCode={handleSubmitCode}
        />
      </div>
    </div>
  );
};

const loginRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/login",
  async beforeLoad() {
    const { data } = await supabase.auth.getSession();
    if (data.session) {
      throw redirect({
        to: "/dashboard",
      });
    }
  },
  component: LoginRoute,
});

export default loginRoute;
