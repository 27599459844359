import { supabase } from "@/config/supabase";

export const createAlert = async (alert: {
  dashboardId: number;
  userId: string;
  name: string;
  min: number | null;
  max: number | null;
  variable: string;
  media: ("email" | "push")[];
}) => {
  const result = await supabase.from("alerts").insert({
    dashboard_id: alert.dashboardId,
    enabled: true,
    name: alert.name,
    rule: {
      min: alert.min,
      max: alert.max,
      variable: alert.variable,
    },
    user_id: alert.userId,
    media: alert.media,
  }).select(`
    id,
    rule,
    user_id,
    name,
    enabled,
    dashboards (
      provider,
      config
    ),
    profiles (
      email
    )
  `);

  if (result.error) {
    throw new Error(result.error.message);
  }

  return result;
};
