import { createRoute } from "@tanstack/react-router";
import PageContainer from "@/components/common/PageContainer";
import { adminRoute } from "./root";
import AddDashboard from "@/components/users/AddDashboard";
import { profileService } from "@/services/queries/profile/profileService";

const AdminSettingsDashboard = () => {
  return (
    <PageContainer className="p-50 justify-center items-center">
      <AddDashboard />
    </PageContainer>
  );
};

export const adminSettingsDashboardRoute = createRoute({
  getParentRoute: () => adminRoute,
  path: "/dashboard",
  loader: async ({ context: { queryClient } }) => {
    await queryClient.ensureQueryData({
      queryKey: ["profiles"],
      queryFn: profileService.getProfilesForCompany,
    });
  },
  component: AdminSettingsDashboard,
});
