import { defaultExpiryTime } from "./constants";

interface IxonLoginResults {
  status: "success" | "failed";
  type: "AccessTokenCreateResponse";
  data: {
    publicId: string;
    expiresOn: string;
    secretId: string;
    user: {
      publicId: string;
    };
  };
}

interface IxonLoginError {
  status: "failed";
  type: "AccessTokenCreateResponse";
  data: {
    message: string;
  }[];
}

export const loginToIxon = async ({
  username,
  password,
  twoFactorCode,
}: {
  username: string;
  password: string;
  twoFactorCode: string;
}) => {
  const authorization = twoFactorCode
    ? `Basic ${btoa(`${username}:${twoFactorCode}:${password}`)}`
    : `Basic ${btoa(`${username}::${password}`)}`;

  const res = await fetch("https://portal.ixon.cloud/api/access-tokens", {
    body: JSON.stringify({
      expiresIn: defaultExpiryTime,
    }),
    headers: {
      "Api-Version": "2",
      "Api-Application": "9J9IZzeT4xN4",
      "Content-Type": "application/json",
      Authorization: authorization,
    },
    method: "POST",
  });

  const json = await res.json();

  if (res.ok) {
    return {
      ok: true,
      json: json as IxonLoginResults,
      error: null,
    };
  }
  return {
    ok: false,
    json: null,
    error: json as IxonLoginError,
  };
};

interface IxonRefreshTokenResults {
  status: "success" | "failed";
  type: "Null";
  data: null;
}

export const refreshToken = async (token: string, tokenId: string) => {
  const res = await fetch(
    `https://portal.ixon.cloud/api/access-tokens/${tokenId}`,
    {
      body: JSON.stringify({
        expiresIn: defaultExpiryTime,
      }),
      headers: {
        "Api-Version": "2",
        "Api-Application": "9J9IZzeT4xN4",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      method: "PATCH",
    }
  );

  const json = await res.json();

  if (res.ok) {
    return {
      ok: true,
      json: json as IxonRefreshTokenResults,
      error: null,
    };
  }
  return {
    ok: false,
    json: null,
    error: json,
  };
};
