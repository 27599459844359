import InstallationsItem from "./InstallationItem";

interface InstallationsListProps {
  connections:
    | {
        name: "ixon" | "vrm";
        installations: {
          id: string;
          name: string;
        }[];
      }[]
    | undefined;
  onClick: (id: string) => void;
}

const connectionMap = {
  vrm: "VRM",
  ixon: "IXON",
};

const InstallationsList = ({
  connections,
  onClick,
}: InstallationsListProps) => {
  return (
    <div>
      {connections?.map((connection, index) => (
        <div key={connection.name}>
          <p className="text-black pt-6 pb-3">
            {connectionMap[connection.name]}
          </p>
          <ul className="list-disc flex-col items-start list-none">
            {connection.installations.map((installation) => (
              <InstallationsItem
                key={installation.id}
                id={installation.id}
                name={installation.name}
                onClick={onClick}
              />
            ))}
          </ul>
          {index !== 0 && <div className="bg-slate-200 h-px mt-4" />}
        </div>
      ))}
    </div>
  );
};

export default InstallationsList;
