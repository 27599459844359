import { supabase } from "@/config/supabase";
import useStore from "@/store/store";
import { setSentryConfig } from "@/utils/sentry";
import { useEffect } from "react";

export const useInitializeUser = () => {
  const { setUser } = useStore();

  useEffect(() => {
    const listener = supabase.auth.onAuthStateChange((_, session) => {
      setUser(session?.user ?? null);
      if (session?.user) {
        setSentryConfig(session?.user);
      }
    });

    return () => {
      listener.data.subscription.unsubscribe();
    };
  }, [setUser]);
};
