import { cn } from "@/lib/utils";
import { Button } from "../ui/button";
import { FaCog } from "react-icons/fa";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import Filter from "./Filter";
import { useState } from "react";
import { Switch } from "../ui/switch";
import { Label } from "../ui/label";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  DashboardSettings,
  DashboardSettingsSchema,
} from "./DashboardSettings.schema";
import toast from "react-hot-toast";
import { useDashboard } from "@/hooks/useDashboard";

const DashboardSettingsMenu = () => {
  const { settings, setDashboardSettings } = useDashboard();

  const { control, handleSubmit } = useForm<DashboardSettings>({
    defaultValues: settings,
    resolver: zodResolver(DashboardSettingsSchema),
  });

  const [menuOpen, setMenuOpen] = useState(false);

  const submit = handleSubmit((data) => {
    setDashboardSettings(data);
    setMenuOpen(false);
    toast("Settings saved", { position: "bottom-center", icon: "🚀" });
  });

  return (
    <Dialog open={menuOpen} onOpenChange={setMenuOpen}>
      <DialogTrigger asChild>
        <Button
          variant="outline"
          className={cn("justify-start text-left font-normal")}
        >
          <FaCog />
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[500px] md:min-w-1/3">
        <form onSubmit={submit} className="flex flex-col gap-5">
          <DialogHeader>
            <DialogTitle>Dashboard settings</DialogTitle>
          </DialogHeader>
          <div className="flex flex-row justify-between items-center">
            <p className="scroll-m-20 text-md font-semibold tracking-tight">
              Chart dates
            </p>
            <Controller
              name="chartDates"
              control={control}
              render={({ field }) => (
                <Filter dateRange={field.value} onSelect={field.onChange} />
              )}
            />
          </div>
          <div className="flex flex-row justify-between items-center">
            <Label
              htmlFor="y-axis-zero"
              className="scroll-m-20 text-md font-semibold tracking-tight"
            >
              Start Y-axis at 0
            </Label>
            <Controller
              name="startFromZero"
              control={control}
              render={({ field }) => (
                <Switch
                  checked={field.value}
                  onCheckedChange={field.onChange}
                  id="y-axis-zero"
                />
              )}
            />
          </div>
          <DialogFooter>
            <Button type="submit">Save changes</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};
export default DashboardSettingsMenu;
