import { Route } from "@tanstack/react-router";
import { adminRoute } from "./root";
import PageContainer from "@/components/common/PageContainer";
import ProviderList from "@/components/connections/ProviderList";

const AdminSettingsConnections = () => {
  return (
    <PageContainer className="p-50 justify-center items-center">
      <div className="flex flex-col items-center w-5/6 md:w-1/2 grow">
        <p className="text-3xl text-black self-start font-semibold mb-10">
          Connections
        </p>
        <p className="text-black">
          Manage the connections of providers you use. You can connect with any
          providers here. Afterwards we manage the connections to the providers
          for you.
        </p>
        <ProviderList />
      </div>
    </PageContainer>
  );
};

export const adminSettingsConnectionRoute = new Route({
  getParentRoute: () => adminRoute,
  path: "/connections",
  component: AdminSettingsConnections,
});
