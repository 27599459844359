import { HttpClient } from "./http";
import { InstallationResponseSchema } from "./types";

export const getInstallations = async (
  httpClient: HttpClient,
  token: string
) => {
  const response = await httpClient.get(`/v2/users/me`, token);

  const userJson = await response.json();

  if (!response.ok) {
    return {
      data: null,
      error: userJson,
    };
  }

  const installationsResponse = await httpClient.get(
    `/v2/users/${userJson.user.id}/installations`,
    token
  );

  const installationsJson = await installationsResponse.json();

  const installations = InstallationResponseSchema.safeParse(installationsJson);

  if (!installations.success) {
    return {
      error: installations.error.flatten().fieldErrors.records,
      data: null,
    };
  }

  return { data: installations.data.records, error: null };
};
