import { Route, redirect } from "@tanstack/react-router";
import { getSession } from "@/services/auth/getSession";
import { rootRoute } from "../rootRoute";
import { queryClient } from "@/config/queryClient";

export const isAuthenticatedMiddleware = new Route({
  getParentRoute: () => rootRoute,
  id: "is-authenticated-middleware",
  beforeLoad: async () => {
    const session = await queryClient.ensureQueryData({
      queryKey: ["session"],
      queryFn: getSession,
    });

    if (!session) {
      throw redirect({
        to: "/login",
      });
    }
  },
});
