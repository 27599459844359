import { createRouter } from "@tanstack/react-router";
import { queryClient } from "@/config/queryClient";
import loginRoute from "./login";
import indexRoute from ".";
import { adminRoute } from "./admin/root";
import { adminSettingsConnectionRoute } from "./admin/connections";
import { adminSettingsDashboardRoute } from "./admin/dashboards";
import { adminSettingsUserRoute } from "./admin/users";
import { dashboardRootRoute } from "./dashboards/root";
import dashboardIndexRoute from "./dashboards";
import dashboardIdRoute from "./dashboards/id";
import { alertsRootRoute } from "./alerts/root";
import alertsIndexRoute from "./alerts";
import { rootRoute } from "./rootRoute";
import { isAuthenticatedMiddleware } from "./middleware/authenticated";
import { isOwnerMiddleware } from "./middleware/owner";
import DefaultError from "./DefaultError";

const routeTree = rootRoute.addChildren([
  isAuthenticatedMiddleware.addChildren([
    dashboardRootRoute.addChildren([dashboardIndexRoute, dashboardIdRoute]),
    alertsRootRoute.addChildren([alertsIndexRoute]),
  ]),
  isOwnerMiddleware.addChildren([
    adminRoute.addChildren([
      adminSettingsConnectionRoute,
      adminSettingsDashboardRoute,
      adminSettingsUserRoute,
    ]),
  ]),
  indexRoute,
  loginRoute,
]);

const router = createRouter({
  routeTree,
  defaultPreload: "intent",
  defaultErrorComponent: DefaultError,
  context: {
    queryClient,
  },
});

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

export default router;
