// import { ChartData } from "@battery-monitor/types";

type Widget = "outputPower" | "inputPower" | "batterySoc" | "inputVoltage";

interface Series {
  [key: string]: number;
  date: number;
}

export type ChartDataSeries = (readonly [number, number])[];

export interface ChartData<C> {
  code: C;
  data: ChartDataSeries;
  description: string;
  components: {
    visible: boolean;
    description: string;
    code: string;
    data: ChartDataSeries;
  }[];
}

export const transformData = (input: ChartData<Widget>): Series[] => {
  const result: Record<number, Series> = {};

  // Process the main data series
  input.data.forEach(([time, value]) => {
    if (!result[time]) {
      result[time] = { date: time };
    }
    result[time][input.description] = value;
  });

  if (input.components.length > 1) {
    // Process each component
    input.components.forEach((component) => {
      if (!component.visible) {
        return;
      }
      component.data.forEach(([time, value]) => {
        if (!result[time]) {
          result[time] = { date: time };
        }
        result[time][component.description] = value;
      });
    });
  }
  // Convert the result object into an array
  return Object.values(result).sort((a, b) => a.date - b.date);
};
