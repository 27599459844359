import type { ProviderClient } from "../client";
import { getInstallations } from "./installations";
import { getChartData } from "./data";
import { Widget, WidgetConfig } from "@battery-monitor/types";
import { Dayjs } from "dayjs";
import { widgetCollection } from "./widgets";
import { getCurrentStateOfCharge } from "./soc";
import { HttpClient } from "./http";

interface VrmProviderChartDataParms {
  id: string;
  widgets: WidgetConfig;
  dates: {
    from: Dayjs;
    to: Dayjs;
  };
  limit: number;
}

export default class VrmProviderClient
  implements ProviderClient<Widget, VrmProviderChartDataParms>
{
  readonly #token: string;
  readonly widgetCollection = widgetCollection;

  static readonly apiUrl = "https://vrmapi.victronenergy.com";
  private httpClient: HttpClient;

  constructor(token: string, baseUrl: string) {
    this.#token = token;
    this.httpClient = new HttpClient(baseUrl);
  }

  static getWidgetCollection() {
    return widgetCollection;
  }

  public async getInstallations() {
    const installationsJson = await getInstallations(
      this.httpClient,
      this.#token
    );
    const individualInstallations = (installationsJson.data?.map((record) => ({
      name: record.name,
      id: record.idSite.toString(),
    })) ?? []) satisfies {
      id: string;
      name: string;
    }[];

    return individualInstallations;
  }

  public async getChartData(params: {
    id: string;
    widgets: WidgetConfig;
    dates: {
      from: Dayjs;
      to: Dayjs;
    };
    limit: number;
  }) {
    const result = await getChartData(
      this.httpClient,
      this.#token,
      params.id,
      params.widgets,
      params.dates
    );

    return result;
  }

  public async getCurrentStateOfCharge(params: { installationId: number }) {
    const result = await getCurrentStateOfCharge(
      this.httpClient,
      params.installationId,
      this.#token
    );

    return result;
  }
}
