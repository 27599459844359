import type { FC } from "react";
import Image from "../../assets/rent_a_battery_logo.png";
import { FaBars } from "react-icons/fa6";
import useStore from "@/store/store";

const Header: FC = () => {
  const toggleSideBarOpen = useStore((state) => state.toggleSideBarOpen);

  return (
    <header className="bg-white text-black py-4 flex">
      <div className="w-20 px-5 self-center">
        <FaBars
          onClick={toggleSideBarOpen}
          size={40}
          className="cursor-pointer"
        />
      </div>
      <div className="grow flex flex-row justify-center">
        <img src={Image} alt="Logo" width={200} height={100} />
      </div>
      <div className="w-20" />
    </header>
  );
};

export default Header;
