import { useNavigate } from "@tanstack/react-router";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { getAllConnections } from "@/services/queries/connections/getAll";
import { getAllInstallations } from "@/services/queries/installations/getAll";
import { adminSettingsDashboardRoute } from "@/routes/admin/dashboards";
import toast from "react-hot-toast";
import SelectGraphs from "./SelectGraphs";
import SelectInstallation from "./SelectInstallation";
import SelectUser from "./SelectUser";
import { Profile, Widget } from "@battery-monitor/types";
import {
  createDashboardData,
  getConfigForIxon,
  getConfigForVrm,
} from "@/utils/createConfig";
import { profileService } from "@/services/queries/profile/profileService";

interface Installation {
  id: string;
  name: string;
  config?: unknown;
}

const AddDashboard = () => {
  const [selectedUser, setSelectedUser] = useState<null | Profile>(null);
  const [selectedInstallation, setSelectedInstallation] =
    useState<null | Installation>(null);
  const [selectedProvider, setSelectedProvider] = useState<
    null | "ixon" | "vrm"
  >(null);

  const queryClient = useQueryClient();

  const profileQueryResult = useQuery({
    queryKey: ["profiles"],
    queryFn: () => profileService.getProfilesForCompany(),
  });

  const connectionsQueryResult = useQuery({
    queryKey: ["connections"],
    queryFn: () => getAllConnections(),
  });

  const installationsQueryResult = useQuery({
    queryKey: ["installations"],
    enabled: !!connectionsQueryResult.data,
    queryFn: () => {
      if (!connectionsQueryResult.data) {
        return [];
      }
      return getAllInstallations(connectionsQueryResult.data);
    },
  });

  const token = connectionsQueryResult.data?.find(
    (c) => c.provider === "ixon"
  )?.token;

  const navigate = useNavigate({
    from: adminSettingsDashboardRoute.id,
  });

  const handleSubmit = async (data: {
    [key in Widget]: {
      visible: boolean;
      components: {
        code: string;
        visible: boolean;
      }[];
    };
  }) => {
    if (selectedProvider && selectedUser && selectedInstallation) {
      let config = null;
      let compositeId = "";

      if (selectedProvider === "vrm") {
        ({ config, compositeId } = getConfigForVrm(selectedInstallation));
      } else if (selectedProvider === "ixon" && token) {
        ({ config, compositeId } = await getConfigForIxon(
          selectedInstallation,
          token
        ));
      }

      if (config) {
        createDashboardMutation.mutate({
          selectedProvider,
          selectedUser,
          selectedInstallation,
          data,
          config,
          compositeId,
        });
      }
    }
  };

  const createDashboardMutation = useMutation({
    mutationFn: createDashboardData,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["dashboards", selectedUser?.id],
      });
      toast.success(`Dashboard ${selectedInstallation?.name} created`, {
        position: "bottom-center",
      });
      navigate({ to: "/dashboard" });
    },
    onError: (error) => {
      if (error.message === "23505") {
        toast.error(
          "A dashboard for this data source already exists for this user",
          { position: "bottom-center" }
        );
      } else {
        toast.error("Creating the dashboard failed...", {
          position: "bottom-center",
        });
      }
    },
  });

  return (
    <div className="bg-white flex flex-col sm:w-1/2 md:w-1/2 grow">
      {!selectedUser && (
        <SelectUser
          users={profileQueryResult.data ?? []}
          onSelect={setSelectedUser}
        />
      )}
      {selectedUser && !selectedInstallation && (
        <SelectInstallation
          connections={installationsQueryResult.data}
          onSelect={(installation, provider) => {
            setSelectedProvider(provider);
            setSelectedInstallation(installation);
          }}
        />
      )}
      {selectedInstallation && selectedProvider && (
        <SelectGraphs provider={selectedProvider} onSubmit={handleSubmit} />
      )}
    </div>
  );
};

export default AddDashboard;
