import { supabase } from "@/config/supabase";
import { dashboardMapper } from "@battery-monitor/mappers";

export const getAllDashboards = async () => {
  const result = await supabase.from("dashboards").select("*");

  if (result.error) {
    throw new Error(result.error.message);
  }

  return result.data.map((dashboard) => dashboardMapper.mapFrom(dashboard));
};

export const getAllDashboardsForUser = async (userId: string) => {
  const result = await supabase
    .from("dashboards")
    .select("*")
    .eq("user_id", userId);

  if (result.error) {
    throw new Error(result.error.message);
  }

  return result.data
    .map((dashboard) => dashboardMapper.mapFrom(dashboard))
    .sort((a, b) => a.name.localeCompare(b.name));
};
