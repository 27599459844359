import { api } from "@/config/api";
import { Dashboard } from "@battery-monitor/mappers";
import {
  IxonProviderClient,
  VrmProviderClient,
} from "@battery-monitor/providers";
import { Dayjs } from "dayjs";
import { z } from "zod";

export const VrmConfigSchema = z.object({
  installationId: z.number({ coerce: true }),
});
export const IxonConfigSchema = z.object({
  datasourceId: z.string(),
  companyId: z.string(),
});

export const getChartDataForDashboard = (
  dashboard: Dashboard,
  dates: {
    from: Dayjs;
    to: Dayjs;
  },
  token: string
) => {
  if (dashboard.provider === "ixon") {
    const client = new IxonProviderClient(token);

    const config = IxonConfigSchema.parse(dashboard.config);

    return client.getChartData({
      datasourceId: config.datasourceId,
      companyId: config.companyId,
      widgets: dashboard.widgets,
      dates,
    });
  }

  const client = new VrmProviderClient(token, `${api.baseUrl}/vrm`);

  const config = VrmConfigSchema.parse(dashboard.config);

  return client.getChartData({
    id: config.installationId.toString(),
    widgets: dashboard.widgets,
    dates,
    limit: 340,
  });
};

export const getBatteryState = async (dashboard: Dashboard, token: string) => {
  if (dashboard.provider === "ixon") {
    const client = new IxonProviderClient(token);

    const config = IxonConfigSchema.parse(dashboard.config);

    return client.getCurrentStateOfCharge({
      datasourceId: config.datasourceId,
      companyId: config.companyId,
    });
  }

  const client = new VrmProviderClient(token, `${api.baseUrl}/vrm`);

  const config = VrmConfigSchema.parse(dashboard.config);

  return client.getCurrentStateOfCharge({
    installationId: config.installationId,
  });
};
