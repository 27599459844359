import dayjs, { Dayjs } from "dayjs";
import { ProviderClient } from "../client";
import { getAgents, getCompanies, getDataSources } from "./agents";
import { getData } from "./data";
import {
  ChartDataCollection,
  Widget,
  WidgetConfig,
} from "@battery-monitor/types";
import { widgetCollection } from "./widgets";

interface IxonProviderChartDataParams {
  companyId: string;
  datasourceId: string;
  widgets: WidgetConfig;
  dates: {
    from: Dayjs;
    to: Dayjs;
  };
}

export default class IxonProviderClient
  implements
    ProviderClient<
      Widget &
        ("input-power-charge" | "input-power-cee" | "external-power-meter"),
      IxonProviderChartDataParams
    >
{
  readonly #token: string;
  readonly widgetCollection = widgetCollection;

  static getWidgetCollection() {
    return widgetCollection;
  }

  constructor(token: string) {
    this.#token = token;
  }

  public async getInstallations() {
    const companiesJson = await getCompanies(this.#token);
    const agentsJson =
      companiesJson?.status === "success"
        ? await getAgents(this.#token, companiesJson.data[0].publicId)
        : null;

    const individualInstallations =
      agentsJson?.data?.map((record) => ({
        name: record.name,
        id: record.publicId.toString(),
        config: {
          agentId: record.publicId,
          companyId: companiesJson?.data[0].publicId,
        },
      })) ?? [];

    return individualInstallations;
  }

  public async getDataSources(params: { agentId: string; companyId: string }) {
    return getDataSources(this.#token, params);
  }

  private calculateWidgetData(
    statistic: Widget,
    points: {
      time: string;
      values: {
        "load-power": number;
        "input-power-charge": number;
        "input-power-cee": number;
        "external-power-meter": number;
        "selected-soc": number;
        "input-voltage-1": number;
        "input-voltage-2": number;
        "input-voltage-3": number;
      };
    }[]
  ) {
    const data: {
      [key in Widget]: {
        code: string;
        data: [number, number][];
        components: {
          code: string;
          data: [number, number][];
        }[];
      };
    } = {
      inputPower: {
        code: "inputPower",
        data: [],
        components: [
          { code: "input-power-charge", data: [] },
          { code: "input-power-cee", data: [] },
          { code: "external-power-meter", data: [] },
        ],
      },
      outputPower: {
        code: "outputPower",
        data: [],
        components: [{ code: "load-power", data: [] }],
      },
      batterySoc: {
        code: "batterySoc",
        data: [],
        components: [{ code: "selected-soc", data: [] }],
      },
      inputVoltage: {
        code: "inputVoltage",
        data: [],
        components: [
          { code: "input-voltage-1", data: [] },
          { code: "input-voltage-2", data: [] },
          { code: "input-voltage-3", data: [] },
        ],
      },
    };

    points.forEach((record) => {
      const time = new Date(record.time).getTime();
      const values = record.values;

      const inputPowerCharge = values["input-power-charge"];
      const inputPowerCee = values["input-power-cee"];
      const externalPowerMeter = values["external-power-meter"];
      const inputPower = inputPowerCharge + inputPowerCee + externalPowerMeter;

      const outputPower = values["load-power"];

      const batterySoc = values["selected-soc"];

      const inputVoltage =
        values["input-voltage-1"] +
        values["input-voltage-2"] +
        values["input-voltage-3"];
      const inputVoltageL1 = values["input-voltage-1"];
      const inputVoltageL2 = values["input-voltage-2"];
      const inputVoltageL3 = values["input-voltage-3"];

      switch (statistic) {
        case "inputPower":
          data.inputPower.data.push([time, inputPower]);
          data.inputPower.components[0].data.push([time, inputPowerCharge]);
          data.inputPower.components[1].data.push([time, inputPowerCee]);
          data.inputPower.components[2].data.push([time, externalPowerMeter]);
          break;

        case "outputPower":
          data.outputPower.data.push([time, outputPower]);
          data.outputPower.components[0].data.push([time, outputPower]);
          break;

        case "batterySoc":
          data.batterySoc.data.push([time, batterySoc]);
          data.batterySoc.components[0].data.push([time, batterySoc]);
          break;

        case "inputVoltage":
          data.inputVoltage.data.push([time, inputVoltage]);
          data.inputVoltage.components[0].data.push([time, inputVoltageL1]);
          data.inputVoltage.components[1].data.push([time, inputVoltageL2]);
          data.inputVoltage.components[2].data.push([time, inputVoltageL3]);
          break;
      }
    });

    return data;
  }

  private async getData(
    params: Omit<IxonProviderChartDataParams, "widgets"> & {
      codes: string[];
      limit: number;
    }
  ) {
    const result = await getData({ ...params, token: this.#token });

    return result;
  }

  public async getChartData(params: IxonProviderChartDataParams) {
    const codes = Object.values(params.widgets).reduce<string[]>(
      (list, widget) => {
        list.push(widget.code);

        widget.components.forEach((component) => {
          if (component.visible) {
            list.push(component.code);
          }
        });
        return list;
      },
      []
    );

    const chartData = await this.getData({ ...params, limit: 340, codes });

    return Object.entries(params.widgets).reduce<ChartDataCollection>(
      (acc, [key, value]) => {
        const widget = key as Widget;
        const data = this.calculateWidgetData(widget, chartData.data[0].points);

        acc[widget] = {
          code: value.code,
          data: data[widget]!.data,
          description: widgetCollection[widget].description,
          components: data[widget]!.components.map((component) => ({
            code: component.code,
            data: component.data,
            visible: value.components.find((c) => c.code === component.code)!
              .visible,
            description: widgetCollection[widget].components.find(
              (c) => c.code === component.code
            )!.description,
          })),
        };

        return acc;
      },
      {} as ChartDataCollection
    );
  }

  public async getCurrentStateOfCharge(
    params: Omit<IxonProviderChartDataParams, "widgets" | "dates">
  ) {
    const chartData = await this.getData({
      ...params,
      limit: 1,
      dates: {
        from: dayjs().subtract(1, "day"),
        to: dayjs(),
      },

      codes: ["selected-soc"],
    });

    return chartData.data[0].points[0].values["selected-soc"];
  }
}
