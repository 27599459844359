import useStore from "@/store/store";

export const useDashboard = () => {
  const setDashboardSettings = useStore((state) => state.setDashboardSettings);
  const settings = useStore((state) => state.settings);

  const zoom = useStore((state) => state.zoom);
  const zoomProps = useStore((state) => state.zoomProps);

  return { setDashboardSettings, settings, zoom, zoomProps };
};
