const dotsAnimation = () => {
  const dotsAnimation = `
  @keyframes dotFlashing {
    0%, 100% { background-color: #1F2937; } /* Tailwind's gray-300 */
    50% { background-color: #ffffff; } /* White color */
}

.dot-flashing {
    animation: dotFlashing 2s infinite linear alternate;
}
  `;

  return (
    <>
      <style>{dotsAnimation}</style>
      <div className="flex w-full bg-gray-900 justify-center items-center">
        <div
          className="w-2.5 h-2.5 bg-gray-900 rounded-full mr-1 dot-flashing"
          style={{ animationDelay: "-0.48s" }}
        ></div>
        <div
          className="w-2.5 h-2.5 bg-gray-900 rounded-full mr-1 dot-flashing"
          style={{ animationDelay: "-0.32s" }}
        ></div>
        <div
          className="w-2.5 h-2.5 bg-gray-900 rounded-full mr-1 dot-flashing"
          style={{ animationDelay: "-0.16s" }}
        ></div>
        <div className="w-2.5 h-2.5 bg-gray-900 rounded-full dot-flashing"></div>
      </div>
    </>
  );
};

export default dotsAnimation;
