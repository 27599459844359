import {
  ChartDataCollection,
  Widget,
  WidgetConfig,
} from "@battery-monitor/types";
import { Dayjs } from "dayjs";
import { widgetCollection } from "./widgets";
import { getDiagnostics } from "./diagnostics";
import { HttpClient } from "./http";

type Requirement =
  | "IP1"
  | "IP2"
  | "IP3"
  | "OP1"
  | "OP2"
  | "OP3"
  | "SOC"
  | "IV1"
  | "IV2"
  | "IV3";

interface WidgetDefition {
  requirements: Requirement[];
  data: (requirements: [number, number][]) => [number, number];
  transform?: (x: number) => number;
}

const widgetDefinitions = {
  vrm: {
    inputPower: {
      transform: (x) => Math.round(x / 1000),
      requirements: ["IP1", "IP2", "IP3"],
      data: (requirements: [number, number][]): [number, number] =>
        requirements.reduce(
          (sum, val) => {
            sum[0] = val[0];
            sum[1] += val[1];
            return sum;
          },
          [0, 0]
        ),
    },
    outputPower: {
      transform: (x) => Math.round(x / 1000),
      requirements: ["OP1", "OP2", "OP3"],
      data: (requirements: [number, number][]): [number, number] =>
        requirements.reduce(
          (sum, val) => {
            sum[0] = val[0];
            sum[1] += val[1];
            return sum;
          },
          [0, 0]
        ),
    },
    batterySoc: {
      requirements: ["SOC"],
      data: (requirements: [number, number][]): [number, number] => [
        requirements[0][0],
        requirements[0][1],
      ],
    },
    inputVoltage: {
      requirements: ["IV1", "IV2", "IV3"],
      data: (requirements: [number, number][]): [number, number] => [
        requirements[0][0],
        requirements[0][1],
      ],
    },
  },
} satisfies { vrm: { [K in Widget]: WidgetDefition } };

export const createVrmConfig = (widgets: WidgetConfig) => {
  return Object.values(widgets).reduce<
    {
      requirements: string[];
      transform?: (x: number) => number;
      data: (requirements: [number, number][]) => [number, number];
      widget: Widget;
    }[]
  >((list, widget) => {
    const newList = [
      ...list,
      {
        widget: widget.code,
        ...widgetDefinitions.vrm[widget.code],
      },
    ];

    return newList;
  }, []);
};

export const getData = async (
  httpClient: HttpClient,
  id: string,
  token: string,
  requiredCodes: string[],
  dates: {
    from: Dayjs;
    to: Dayjs;
  }
) => {
  const diagnostics = await getDiagnostics(httpClient, parseInt(id), token);

  const instanceConfig = diagnostics.records
    .filter((record) => requiredCodes.includes(record.code))
    .reduce<{
      [key: string]: string[];
    }>((conf, record) => {
      if (conf[record.instance]) {
        conf[record.instance].push(record.code);
      } else {
        conf[record.instance] = [record.code];
      }
      return conf;
    }, {});

  const searchParamObjects = Object.entries(instanceConfig).map(
    ([instance, codes]) => {
      const searchParams = new URLSearchParams();

      searchParams.append("instance", instance);
      searchParams.append("end", dates.to.unix().toString());
      searchParams.append("start", dates.from.unix().toString());
      codes.forEach((code) => searchParams.append("attributeCodes[]", code));
      return searchParams;
    }
  );

  const dataResponse = await Promise.all(
    searchParamObjects.map(async (searchParams) => {
      return await httpClient.get(
        `/v2/installations/${id}/widgets/Graph?${searchParams.toString()}`,
        token
      );
    })
  );

  const data = (await Promise.all(
    dataResponse.map((promise) => promise.json())
  )) as {
    records: {
      data: { [key: string]: [number, number][] };
      meta: {
        [key: string]: {
          code: string;
        };
      };
    };
  }[];

  const mappedData = data.reduce<{
    [key: string]: [number, number][];
  }>((currentMap, instanceMap) => {
    const map = Object.entries(instanceMap.records.meta).reduce<{
      [key: string]: [number, number][];
    }>((m, [num, { code }]) => {
      return {
        ...m,
        [code]: instanceMap.records.data[num],
      };
    }, {});

    currentMap = { ...currentMap, ...map };

    return currentMap;
  }, {});

  return mappedData;
};

export const getChartData = async (
  httpClient: HttpClient,
  token: string,
  id: string,
  widgets: WidgetConfig,
  dates: {
    from: Dayjs;
    to: Dayjs;
  }
) => {
  const config = createVrmConfig(widgets);

  const requiredCodes = Array.from(
    new Set(config.map((c) => c.requirements).flat(2))
  );

  const mappedData = await getData(httpClient, id, token, requiredCodes, dates);

  return config.reduce<ChartDataCollection>((acc, c) => {
    const values = c.requirements.map((requirement) => mappedData[requirement]);
    const data = values[0]
      .map((_, colIndex) => values.map((row) => row[colIndex]))
      .map((row) => c.data(row))
      .map(
        (row) =>
          [row[0] * 1000, c.transform ? c.transform(row[1]) : row[1]] as const
      );
    const widgetConfig = widgets[c.widget];
    if (widgetConfig) {
      acc[c.widget] = {
        code: c.widget,
        data,
        description: widgetCollection[c.widget].description,
        components: widgetConfig.components.map((component) => ({
          code: component.code,
          data: mappedData[component.code].map(
            (row) =>
              [
                row[0] * 1000,
                c.transform ? c.transform(row[1]) : row[1],
              ] as const
          ),
          visible: component.visible,
          description: widgetCollection[c.widget].components.find(
            (comp) => comp.code === component.code
          )!.description,
        })),
      };
    }

    return acc;
  }, {} as ChartDataCollection);
};
