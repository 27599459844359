import { Route } from "@tanstack/react-router";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { alertsRootRoute } from "./root";
import { getAlerts } from "@/services/queries/alerts/getAlerts";
import PageContainer from "@/components/common/PageContainer";
import AlertTable from "@/components/alerts/AlertsTable";
import { deleteAlert } from "@/services/queries/alerts/deleteAlert";
import AlertsForm from "@/components/alerts/AlertsForm";
import { Alert, Widget } from "@battery-monitor/types";
import { profileService } from "@/services/queries/profile/profileService";

const queryOptions = {
  queryKey: ["alerts"],
  queryFn: getAlerts,
};

const alertsIndexRoute = new Route({
  getParentRoute: () => alertsRootRoute,
  path: "/",
  loader: async ({ context: { queryClient } }) => {
    await queryClient.ensureQueryData(queryOptions);
    await queryClient.ensureQueryData({
      queryKey: ["profiles"],
      queryFn: profileService.getProfilesForCompany,
    });
  },
  component: AlertsIndexRoute,
});

function AlertsIndexRoute() {
  const alertsQueryResult = useQuery(queryOptions);

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: ({ id }: { id: number }) => deleteAlert(id),
    onSuccess: (_, { id }) => {
      queryClient.setQueryData<Alert[]>(["alerts"], (oldData) => {
        return oldData?.filter((element) => element.id !== id);
      });
    },
  });

  return (
    <PageContainer className="p-50 justify-center items-center">
      <div className="flex flex-col w-full md:w-2/3 grow">
        <h1 className="text-3xl text-black self-start font-semibold mb-10">
          Alerts
        </h1>
        <p className="text-black">
          You can manage alerts on this page. An alert is based on a dashboard
          you created for a user. You can select a single variable, and add
          maximum/minimum rules. These rules are checked every hour. If a rule
          is exceeded, we send a push notification if the user has enabled
          those.
        </p>
        <h2 className="text-xl text-black self-start font-semibold my-5">
          Created alerts
        </h2>
        <AlertTable
          alerts={
            alertsQueryResult.data?.map((alert) => {
              const rule = alert.rule as {
                variable: string;
                min?: number;
                max?: number;
              };

              return {
                email: alert.profiles!.email,
                max: rule.max,
                min: rule.min,
                id: alert.id,
                variable: rule.variable as Widget,
                name: alert.name,
              };
            }) ?? []
          }
          onRemove={(id) => mutation.mutate({ id })}
        />
        <h2 className="text-xl text-black self-start font-semibold mt-10">
          Add an alert
        </h2>
        <AlertsForm />
      </div>
    </PageContainer>
  );
}

export default alertsIndexRoute;
