import { getDashboard } from "@/services/queries/dashboards/getDashboard";
import { createRoute } from "@tanstack/react-router";
import { dashboardRootRoute } from "./root";
import { useQuery } from "@tanstack/react-query";
import { getProvider } from "@/services/queries/connections/getProvider";
import dayjs from "dayjs";
import DotsAnimation from "@/components/common/DotsAnimation";
import { getChartDataForDashboard } from "@/services/providers";
import DashboardSettingsMenu from "@/components/dashboard/DashboardSettingsMenu";
import Dashboard from "@/components/dashboard/Dashboard";
import { useDashboard } from "@/hooks/useDashboard";
import DefaultError from "../DefaultError";

const queryOptions = (id: number) => ({
  queryKey: ["dashboards", id],
  queryFn: () => getDashboard(id),
});

const DashboardId = () => {
  const { id } = dashboardIdRoute.useParams();

  const { settings } = useDashboard();

  const dashboardQueryResult = useQuery(queryOptions(parseInt(id)));

  const connection = useQuery({
    enabled: !!dashboardQueryResult.data?.provider,
    queryKey: ["connections", dashboardQueryResult.data?.provider],
    queryFn: () =>
      getProvider(dashboardQueryResult.data!.provider as "ixon" | "vrm"),
  });

  const token = connection.data?.data?.token;

  const chartDataQueryResult = useQuery({
    enabled: !!token && !!dashboardQueryResult.data,
    queryKey: [
      "chartData",
      dashboardQueryResult.data?.config,
      settings.chartDates.from.toISOString(),
      settings.chartDates.to.toISOString(),
    ],
    queryFn: () => {
      if (!token || !dashboardQueryResult.data) {
        throw new Error("No token or dashboard");
      }
      return getChartDataForDashboard(
        dashboardQueryResult.data,
        settings.chartDates,
        token
      );
    },
  });

  return (
    <div className="shadow-md divide-slate-200 bg-white shrink grow flex flex-col overflow-scroll">
      {chartDataQueryResult.data ? (
        <>
          <div className="flex justify-items-stretch items-center bg-gray-900 text-white text-2xl font-semibold font-sans mb-4 py-2 shadow-sm">
            <div className="flex-1 p-4"></div>
            <div className="text-center p-4">
              <h1>{dashboardQueryResult.data?.name}</h1>
            </div>
            <div className="flex-1 flex bg-gray-900 items-center justify-end p-4 text-black">
              <DashboardSettingsMenu />
            </div>
          </div>
          <Dashboard settings={settings} data={chartDataQueryResult.data} />
        </>
      ) : (
        <div className="flex grow justify-items-stretch items-center bg-gray-900 text-white text-2xl font-semibold font-sans mb-4 py-2 shadow-sm">
          <DotsAnimation />
        </div>
      )}
    </div>
  );
};

const dashboardIdRoute = createRoute({
  getParentRoute: () => dashboardRootRoute,
  path: "/$id",
  pendingComponent: DotsAnimation,
  pendingMinMs: 1000,
  loader: async ({ context: { queryClient }, params }) => {
    const dashboard = await queryClient.ensureQueryData(
      queryOptions(parseInt(params.id))
    );

    const connection = await queryClient.ensureQueryData({
      queryKey: ["connections", dashboard.provider],
      queryFn: () => getProvider(dashboard.provider as "ixon" | "vrm"),
    });

    const token = connection.data?.token;

    if (!token) {
      throw new Error("No token or dashboard");
    }

    const chartDates = {
      from: dayjs().subtract(1, "day").startOf("minute"),
      to: dayjs().startOf("minute"),
    };

    await queryClient.ensureQueryData({
      queryKey: [
        "chartData",
        dashboard.config,
        chartDates.from.toISOString(),
        chartDates.to.toISOString(),
      ],
      queryFn: () => getChartDataForDashboard(dashboard, chartDates, token),
    });
  },
  errorComponent: DefaultError,
  component: DashboardId,
});

export default dashboardIdRoute;
