export class HttpClient {
  constructor(private readonly baseUrl: string) {}

  private get headers() {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    return headers;
  }

  public get = async (url: string, token?: string) => {
    const headers = this.headers;
    if (token) {
      headers.append("x-authorization", `Token ${token}`);
    }
    return await fetch(`${this.baseUrl}${url}`, {
      method: "GET",
      headers,
    });
  };

  public post = async (
    url: string,
    body: object,
    token?: string,
    addedHeaders?: Record<string, string>
  ) => {
    const headers = this.headers;
    if (token) {
      headers.append("x-authorization", `Token ${token}`);
    }
    if (addedHeaders?.token) {
      headers.set("x-authorization", `Bearer ${addedHeaders?.token}`);
    }
    return await fetch(`${this.baseUrl}${url}`, {
      method: "POST",
      body: JSON.stringify(body),
      headers,
    });
  };
}
