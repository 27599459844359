import { supabase } from "@/config/supabase";

export const getProvider = async (provider: "vrm" | "ixon") => {
  const connection = await supabase
    .from("connections")
    .select()
    .eq("provider", provider)
    .maybeSingle();

  return connection;
};
