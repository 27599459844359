import { Link, Route, redirect } from "@tanstack/react-router";
import img from "../assets/rent_a_battery_logo.png";
import { supabase } from "@/config/supabase";
import { rootRoute } from "./rootRoute";

const IndexRoute = () => {
  return (
    <div className="flex items-center justify-center grow bg-gray-900">
      <div className="bg-white p-8 rounded-md shadow-md w-[87%] md:w-[30rem] text-center ">
        <h1 className="text-4xl mb-6 text-black">Welcome!</h1>
        <h2 className="text-2xl mb-6 text-black">
          Ready to view your batteries?
        </h2>
        <Link
          to="/login"
          className="flex justify-center grow w-full bg-indigo-600 hover:bg-indigo-700 text-white p-3 rounded-lg font-semibold transition duration-300"
        >
          Login
        </Link>
        <div className="mt-10 flex items-center justify-center">
          <img src={img} alt="Logo" width={200} height={100} />
        </div>
      </div>
    </div>
  );
};

const indexRoute = new Route({
  getParentRoute: () => rootRoute,
  async beforeLoad() {
    const { data } = await supabase.auth.getSession();
    if (data.session) {
      throw redirect({
        to: "/dashboard",
      });
    }
  },
  path: "/",
  component: IndexRoute,
});

export default indexRoute;
