import InstallationsList from "./InstallationsList";

interface SelectInstallationProps {
  connections:
    | {
        name: "ixon" | "vrm";
        installations: {
          id: string;
          name: string;
        }[];
      }[]
    | undefined;
  onSelect: (
    installation: { id: string; name: string },
    provider: "ixon" | "vrm"
  ) => void;
}

const SelectInstallation = ({
  connections,
  onSelect,
}: SelectInstallationProps) => {
  return (
    <div>
      <h1 className="text-3xl text-black font-semibold mb-10">
        Select an installation
      </h1>
      <p className="text-black py-5">
        Pick one of your connected installations.
      </p>
      <InstallationsList
        connections={connections}
        onClick={(id) => {
          const installation = connections?.find((installation) =>
            installation.installations.find((inst) => inst.id === id)
          );
          const inst = installation?.installations.find(
            (inst) => inst.id === id
          );

          if (inst && installation) {
            onSelect(inst, installation.name);
          }
        }}
      />
    </div>
  );
};

export default SelectInstallation;
