import PageContainer from "@/components/common/PageContainer";

const NotFound = () => {
  return (
    <PageContainer>
      <h1 className="text-black text-3xl">Page not found...</h1>
      <div className="grow flex flex-col justify-center items-center">
        <p className="text-black">Hmm, there doesn't seem to be a page here.</p>
      </div>
    </PageContainer>
  );
};

export default NotFound;
