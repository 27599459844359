import dayjs from "dayjs";
import { Button } from "@/components/ui/button";
import { format } from "date-fns";
import { useState } from "react";
import { Calendar } from "../ui/calendar";
import { Input } from "../ui/input";
import { Calendar as CalendarIcon } from "lucide-react";
import { cn } from "@/lib/utils";
import { Dialog, DialogContent, DialogTrigger } from "../ui/dialog";

import { DateRange } from "react-day-picker";

interface FilterProps {
  onSelect: (dates: { from: dayjs.Dayjs; to: dayjs.Dayjs }) => void;
  dateRange: {
    from: dayjs.Dayjs;
    to: dayjs.Dayjs;
  };
}

const Filter = ({ onSelect, dateRange }: FilterProps) => {
  const [range, setRange] = useState(dateRange);
  const [timeFrom, setTimeFrom] = useState("");
  const [timeTo, setTimeTo] = useState("");
  const [calendarOpen, setCalendarOpen] = useState(false);

  const handleSelectDate = (range: DateRange | undefined) => {
    if (range)
      setRange({
        from: dayjs(range.from),
        to: dayjs(range.to),
      });
  };

  const handleDataSelectionSave = () => {
    if (timeFrom && timeTo) {
      onSelect({
        from: range.from
          .hour(parseInt(timeFrom.split(":")[0]))
          .minute(parseInt(timeFrom.split(":")[1])),
        to: range.to
          .hour(parseInt(timeTo.split(":")[0]))
          .minute(parseInt(timeTo.split(":")[1])),
      });
      setCalendarOpen(false);
      return;
    }
    onSelect(range);
    setCalendarOpen(false);
  };

  const handleSelectToday = () => {
    setRange({
      from: dayjs().subtract(1, "day"),
      to: dayjs(),
    });
    onSelect({
      from: dayjs().subtract(1, "day"),
      to: dayjs(),
    });
    setCalendarOpen(false);
  };
  const handleSelectLastSixHours = () => {
    setRange({
      from: dayjs().subtract(6, "hours"),
      to: dayjs(),
    });
    onSelect({
      from: dayjs().subtract(6, "hours"),
      to: dayjs(),
    });
    setCalendarOpen(false);
  };

  const handleSelectLastTwelveHours = () => {
    setRange({
      from: dayjs().subtract(12, "hours"),
      to: dayjs(),
    });
    onSelect({
      from: dayjs().subtract(12, "hours"),
      to: dayjs(),
    });
    setCalendarOpen(false);
  };

  return (
    <Dialog open={calendarOpen} onOpenChange={setCalendarOpen}>
      <DialogTrigger asChild>
        <Button
          variant="outline"
          className={cn(
            "justify-start text-left font-normal",
            !range && "text-muted-foreground"
          )}
        >
          <div className="flex items-center">
            <CalendarIcon className="md:mr-2 h-4 w-4" />
            <span className="hidden md:inline">
              {`${format(range.from.toString(), "LLL dd, y")} - ${format(
                range.to.toString(),
                "LLL dd, y"
              )}`}
            </span>
          </div>
        </Button>
      </DialogTrigger>
      <DialogContent className="w-auto p-0 flex flex-col justify-center">
        <Calendar
          id="chart"
          mode="range"
          defaultMonth={dateRange.from.toDate()}
          selected={{ from: range.from.toDate(), to: range.to.toDate() }}
          onSelect={(range) => handleSelectDate(range)}
          className="text-black p-4 text-center bg-gray-200 items-center flex rounded-md m-2 border"
        />

        <div className="flex flex-row text-center p-1">
          <Input
            className="flex-shrink w-auto"
            type="time"
            value={timeFrom}
            onChange={(e) => setTimeFrom(e.target.value)}
          />
          <p className="flex-grow items-center justify-center rounded-md text-sm font-medium mx-2 py-2 px-4 text-center">
            {format(range.from.toString(), "LLL dd, y")}
          </p>
        </div>
        <div className="flex flex-row text-center p-1">
          <Input
            className="flex-shrink w-auto"
            type="time"
            value={timeTo}
            onChange={(e) => setTimeTo(e.target.value)}
          />
          <p className="flex-grow items-center justify-center rounded-md text-sm font-medium mx-2 py-2 px-4 text-center">
            {format(range.to.toString(), "LLL dd, y")}
          </p>
        </div>
        <Button onClick={handleDataSelectionSave} className="m-2">
          Select Date Range
        </Button>
        <div className="flex column-row justify-space-between">
          <Button onClick={handleSelectToday} className="m-1">
            Today
          </Button>
          <Button onClick={handleSelectLastSixHours} className="m-1">
            Last 6 hours
          </Button>
          <Button onClick={handleSelectLastTwelveHours} className="my-1">
            Last 12 hours
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default Filter;
