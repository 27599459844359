import { SubmitHandler, useForm } from "react-hook-form";
import { FaChevronRight } from "react-icons/fa6";
import {
  IxonProviderClient,
  VrmProviderClient,
} from "@battery-monitor/providers";
import { Button } from "../ui/button";
import { z } from "zod";
import { Widget } from "@battery-monitor/types";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../ui/accordion";

const WidgetsByProvider = {
  ixon: IxonProviderClient.getWidgetCollection(),
  vrm: VrmProviderClient.getWidgetCollection(),
} as const;

interface SelectGraphsProps {
  provider: "ixon" | "vrm";
  onSubmit: (data: {
    [key in Widget]: {
      visible: boolean;
      components: { code: string; visible: boolean }[];
    };
  }) => void;
}

const SelectGraphs = ({ provider, onSubmit }: SelectGraphsProps) => {
  const Widgets = WidgetsByProvider[provider];

  const DashboardFormSchema = z.object({
    [Widgets.batterySoc.code]: z.object({
      code: z.string(),
      visible: z.boolean(),
      components: z.array(
        z.object({
          code: z.string(),
          visible: z.boolean(),
        })
      ),
    }),
    [Widgets.inputPower.code]: z.object({
      code: z.string(),
      visible: z.boolean(),
      components: z.array(
        z.object({
          code: z.string(),
          visible: z.boolean(),
        })
      ),
    }),
    [Widgets.outputPower.code]: z.object({
      code: z.string(),
      visible: z.boolean(),
      components: z.array(
        z.object({
          code: z.string(),
          visible: z.boolean(),
        })
      ),
    }),
    [Widgets.inputVoltage.code]: z.object({
      code: z.string(),
      visible: z.boolean(),
      components: z.array(
        z.object({
          code: z.string(),
          visible: z.boolean(),
        })
      ),
    }),
  });

  type DashboardFormValues = z.infer<typeof DashboardFormSchema>;

  const { register, handleSubmit, setValue, getValues } =
    useForm<DashboardFormValues>({
      defaultValues: {
        batterySoc: {
          code: "batterySoc",
          visible: true,
          components: Widgets.batterySoc.components.map((c) => ({
            code: c.code,
            visible: true,
          })),
        },
        outputPower: {
          code: "outputPower",
          visible: true,
          components: Widgets.outputPower.components.map((c) => ({
            code: c.code,
            visible: true,
          })),
        },
        inputPower: {
          code: "inputPower",
          visible: true,
          components: Widgets.inputPower.components.map((c) => ({
            code: c.code,
            visible: true,
          })),
        },
        inputVoltage: {
          code: "inputVoltage",
          visible: true,
          components: Widgets.inputVoltage.components.map((c) => ({
            code: c.code,
            visible: true,
          })),
        },
      },
    });

  const handleDone: SubmitHandler<DashboardFormValues> = async (data) =>
    onSubmit(data);

  return (
    <form className="flex flex-col gap-2" onSubmit={handleSubmit(handleDone)}>
      <h1 className="text-3xl text-black font-semibold mb-10">Select graphs</h1>
      <p className="text-black pb-10">
        Choose what graphs should be shown to the user. When you press done, the
        dashboard is saved for the selected user.
      </p>
      <Accordion type="multiple">
        {(
          ["outputPower", "inputPower", "batterySoc", "inputVoltage"] as const
        ).map((widget) => {
          if (getValues()[widget].components.length === 1) {
            return (
              <div
                key={widget}
                className="p-2 hover:bg-slate-100 cursor-pointer flex row items-center grow"
                onClick={() => {
                  const values = getValues();
                  const currentValue = values[widget].visible;
                  setValue(`${widget}.visible`, !currentValue);
                }}
              >
                <input
                  {...register(`${widget}.visible`)}
                  id={widget}
                  type="checkbox"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                />
                <label className="ms-2 text-black">
                  {Widgets[widget].description}
                </label>
              </div>
            );
          }

          return (
            <AccordionItem key={widget} value={widget}>
              <AccordionTrigger>
                <div
                  key={widget}
                  className="p-2 hover:bg-slate-100 cursor-pointer flex row items-center grow"
                  onClick={() => {
                    const values = getValues();
                    const currentValue = values[widget].visible;
                    setValue(`${widget}.visible`, !currentValue);
                  }}
                >
                  <input
                    {...register(`${widget}.visible`)}
                    id={widget}
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                  />
                  <label className="ms-2 text-black">
                    {Widgets[widget].description}
                  </label>
                </div>
              </AccordionTrigger>
              {getValues()[widget].components.length > 1
                ? getValues()[widget].components.map((component, index) => (
                    <AccordionContent
                      key={component.code}
                      className="ml-8 p-2 hover:bg-slate-100 cursor-pointer flex row items-center"
                      onClick={() => {
                        const values = getValues();
                        const currentValue = values[widget].components.find(
                          (c) => c.code === component.code
                        )?.visible;
                        setValue(
                          `${widget}.components.${index}.visible`,
                          !currentValue
                        );
                      }}
                    >
                      <input
                        {...register(`${widget}.components.${index}.visible`)}
                        id={component.code}
                        type="checkbox"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                      />
                      <label className="ms-2 text-black">
                        {
                          Widgets[widget].components.find(
                            (c) => c.code === component.code
                          )?.description
                        }
                      </label>
                    </AccordionContent>
                  ))
                : null}
            </AccordionItem>
          );
        })}
      </Accordion>
      <div className="flex justify-center p-8 grow flex items-center justify-end flex-col">
        <Button type="submit" size="lg">
          Save
          <FaChevronRight size={20} />
        </Button>
      </div>
    </form>
  );
};

export default SelectGraphs;
