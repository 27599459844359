import { DashboardSettings } from "@/components/dashboard/DashboardSettings.schema";
import dayjs from "dayjs";
import { StateCreator } from "zustand";

export interface DashboardSlice {
  zoom: (startAndEnd: [number, number] | [null, null]) => void;
  zoomProps: [number, number] | [null, null];
  settings: DashboardSettings;
  setDashboardSettings: (settings: DashboardSettings) => void;
}

const createDashboardSlice: StateCreator<
  DashboardSlice,
  [],
  [],
  DashboardSlice
> = (set) => ({
  settings: {
    startFromZero: true,
    chartDates: {
      from: dayjs().subtract(1, "day").startOf("minute"),
      to: dayjs().startOf("minute"),
    },
  },
  zoomProps: [null, null],
  zoom: (startAndEnd) =>
    set(() => {
      if (startAndEnd[0] === null) {
        return { zoomProps: [null, null] };
      }

      const zoomLeft = startAndEnd[0];
      const zoomRight = startAndEnd[1];

      if (zoomLeft === zoomRight) {
        return { zoomProps: [null, null] };
      }

      return { zoomProps: startAndEnd };
    }),
  setDashboardSettings: (settings: DashboardSettings) =>
    set(() => ({ settings })),
});

export default createDashboardSlice;
