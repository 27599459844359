import dayjs from "dayjs";
import { TooltipProps } from "recharts";

const ChartTooltip = ({
  active,
  payload,
  label,
  unit,
}: TooltipProps<number | string, ""> & { unit: string }) => {
  if (active && payload?.length) {
    return (
      <div className="custom-tooltip text-black bg-white rounded-md p-4">
        <p className="label font-semibold pb-2">{`${dayjs(label).format(
          "YYYY-MM-DD HH:mm"
        )} `}</p>
        <ul className="label">
          {payload.map((type, index) => (
            <li className="flex flex-row" key={index}>
              <p>{type.name} </p>
              <p>
                : {type.value} {unit}
              </p>
            </li>
          ))}
        </ul>
      </div>
    );
  }
};

export default ChartTooltip;
