import { useState } from "react";
import LoginForm from "./LoginForm";
import CodeForm from "./CodeForm";
import {
  AuthenticationState,
  ICodeForm,
  ILoginForm,
} from "./AuthenticationForms.schema";

interface AuthenticationFormProps {
  onSubmit: (form: { email: string }) => Promise<boolean>;
  onSubmitCode: (form: { code: string; email: string }) => void;
}

const AuthenticationForm = ({
  onSubmit,
  onSubmitCode,
}: AuthenticationFormProps) => {
  const [state, setState] = useState(AuthenticationState.Login);
  const [email, setEmail] = useState("");

  const handleLogin = async (form: ILoginForm) => {
    const didLoginSucceed = await onSubmit(form);

    if (didLoginSucceed) {
      setState(AuthenticationState.Code);
      setEmail(form.email);
    }
  };

  const handleCode = async (form: ICodeForm) =>
    onSubmitCode({ code: form.code, email });

  if (state === AuthenticationState.Login) {
    return <LoginForm onSubmit={handleLogin} />;
  }

  return <CodeForm email={email} onSubmit={handleCode} />;
};

export default AuthenticationForm;
