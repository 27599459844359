import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { supabase } from "@/config/supabase";
import { deleteUser } from "@/services/api/deleteUser";
import { createUser } from "@/services/api/createUser";
import { Profile } from "@battery-monitor/types";
import useStore from "@/store/store";
import RemoveUserDialog from "./RemoveUserDialog";
import { Button } from "../ui/button";
import { IoReloadCircleOutline } from "react-icons/io5";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { profileService } from "@/services/queries/profile/profileService";

const AddUser = () => {
  const [email, setEmail] = useState("");

  const { user } = useStore();
  const queryClient = useQueryClient();

  const companyIdQueryResult = useQuery({
    queryKey: ["owners", "companyid"],
    queryFn: async () => {
      const userId = (await supabase.auth.getUser()).data.user?.id;

      return profileService.getCompanyId(userId ?? "");
    },
  });

  const invitedUsers = useQuery({
    queryKey: ["profiles"],
    queryFn: profileService.getProfilesForCompany,
  });

  const mutation = useMutation({
    mutationFn: async ({ id }: { id: string }) => {
      const session = await supabase.auth.getSession();
      const accessToken = session.data.session?.access_token;
      if (accessToken) {
        return await deleteUser(accessToken, id);
      }
    },
    onSuccess: (_, { id }) => {
      queryClient.setQueryData<Profile[]>(["profiles"], (oldData) => {
        return oldData?.filter((element) => element.id !== id);
      });
      toast.success(`User deleted successfully`, {
        position: "bottom-center",
      });
    },
    onError: () => {
      toast.error(`Deleting user failed`, {
        position: "bottom-center",
      });
    },
  });

  const inviteMutation = useMutation({
    mutationFn: async ({ email }: { email: string }) => {
      const session = await supabase.auth.getSession();
      const accessToken = session.data.session?.access_token;
      if (accessToken) {
        return await await createUser(accessToken, email);
      }
    },
    onSuccess: (data) => {
      queryClient.setQueryData<Profile[]>(["profiles"], (oldData) => {
        return [...(oldData ?? []), data];
      });
      toast.success(`User invited successfully`, {
        position: "bottom-center",
      });
      setEmail("");
    },
    onError: () => {
      toast.error(`Inviting user failed`, {
        position: "bottom-center",
      });
    },
  });

  const handleInvite = async () => {
    if (email && companyIdQueryResult.data) {
      inviteMutation.mutate({ email });
    }
  };

  return (
    <div>
      <h1 className="text-3xl text-black font-semibold mb-10">Users</h1>
      <p className="pb-10 text-black">
        Let's invite some users. The user will receive an email with login
        instructions
      </p>
      <div className="grid grid-cols-1 gap-10">
        <div className="mb-4 flex flex-col gap-2">
          <Label htmlFor="email">Invite User</Label>
          <div className="flex flex-col md:flex-row items-start md:items-center gap-5">
            <Input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full grow shrink basis-auto min-w-0"
              placeholder="Enter user email"
            />
            <Button onClick={handleInvite}>
              {inviteMutation.isPending ? (
                <IoReloadCircleOutline className="mr-2 h-4 w-4 animate-spin" />
              ) : (
                "Invite"
              )}
            </Button>
          </div>
        </div>
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Invited Users
          </label>
          <ul>
            {invitedUsers.data
              ?.filter((u) => u.id !== user?.id)
              .map((user, index) => (
                <li
                  key={index}
                  className="text-gray-700 py-1 flex flex-row justify-between items-center"
                >
                  <p>{user.email}</p>
                  <RemoveUserDialog
                    onRemove={() => mutation.mutate({ id: user.id })}
                  />
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AddUser;
