import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { CodeFormSchema, ICodeForm } from "./AuthenticationForms.schema";

interface CodeFormProps {
  email: string;
  onSubmit: (form: ICodeForm) => void;
}

const CodeForm = ({ email, onSubmit }: CodeFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ICodeForm>({
    defaultValues: { code: "" },
    resolver: zodResolver(CodeFormSchema),
  });

  const submit = handleSubmit((data) => onSubmit(data));

  return (
    <form onSubmit={submit} className="w-full">
      <div className="mb-4">
        <p className="text-black text-center mb-2">
          An email has been sent to {email}. Check your inbox!
        </p>
        <p className="text-black text-center mb-2">
          Fill out the code you received below.
        </p>
        <label
          htmlFor="number"
          className="block text-sm font-medium text-gray-700 text-black"
        >
          Code
        </label>
        <input
          {...register("code")}
          type="text"
          className="mt-1 p-3 w-full rounded-lg border-2 border-gray-300 text-black text-center text-2xl"
          placeholder="000000"
        />
        {errors.code && (
          <p className="text-red-500 text-sm mt-1">{errors.code.message}</p>
        )}
      </div>
      <button
        type="submit"
        className="w-full bg-indigo-600 hover:bg-indigo-700 text-white p-3 rounded-lg font-semibold transition duration-300"
      >
        Login
      </button>
    </form>
  );
};

export default CodeForm;
