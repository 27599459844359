import { HttpClient } from "./http";

interface VrmLoginResults {
  token: string;
  idUser: number;
  verification_mode: string;
  verification_sent: boolean;
}
interface VrmLoginError {
  errors: string;
}

interface VrmTokenResults {
  token: string;
  success: boolean;
}

export const loginToVrm = async ({
  httpClient,
  username,
  password,
  twoFactorCode,
}: {
  httpClient: HttpClient;
  username: string;
  password: string;
  twoFactorCode: string;
}) => {
  const body = twoFactorCode
    ? { username, password, sms_token: twoFactorCode }
    : { username, password };

  const res = await httpClient.post("/v2/auth/login", body);

  if (res.ok) {
    const json = (await res.json()) as VrmLoginResults;
    return {
      ok: true,
      json,
      error: null,
    };
  }
  const json = (await res.json()) as VrmLoginError;
  return {
    ok: false,
    json: null,
    error: json,
  };
};

export const createAccessToken = async ({
  httpClient,
  userId,
  token,
}: {
  httpClient: HttpClient;
  userId: number;
  token: string;
}) => {
  const res = await httpClient.post(
    `/v2/users/${userId}/accesstokens/create`,
    {
      name: Date.now().toString(),
    },
    undefined,
    { token }
  );

  if (res.ok) {
    const json = (await res.json()) as VrmTokenResults;
    return {
      ok: true,
      json: json.token,
      error: null,
    };
  } else {
    const json = (await res.json()) as VrmTokenResults;
    return {
      ok: false,
      json: null,
      error: json,
    };
  }
};
