import { useEffect } from "react";
import { ErrorComponentProps } from "@tanstack/react-router";
import * as Sentry from "@sentry/react";

const DefaultError = ({ error }: ErrorComponentProps) => {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <div className="w-full h-full flex flex-col justify-center items-center gap-5">
      <h1 className="text-xl font-semibold">Oops! An error happened...</h1>
      <h1 className="text-md">
        We are notified of this error and will have a look.
      </h1>
    </div>
  );
};

export default DefaultError;
