import { supabase } from "@/config/supabase";
import { useState } from "react";
import Provider from "./Provider";
import { queryClient } from "@/config/queryClient";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getAllConnections } from "@/services/queries/connections/getAll";
import { getOwner } from "@/services/queries/owners/getOwner";
import toast from "react-hot-toast";
import {
  createAccessToken,
  loginToIxon,
  loginToVrm,
} from "@battery-monitor/providers";
import { HttpClient } from "@battery-monitor/providers/src/vrm/http";
import { api } from "@/config/api";

const providers = [
  { provider: "vrm", name: "VRM" },
  { provider: "ixon", name: "IXON" },
] as const;

const ProviderList = () => {
  const connectionsQueryResult = useQuery({
    queryKey: ["connections"],
    queryFn: () => getAllConnections(),
  });
  const connections = connectionsQueryResult.data ?? [];

  const ownerQueryResult = useQuery({
    queryKey: ["owner"],
    queryFn: getOwner,
  });
  const owner = ownerQueryResult.data;

  const connectionsList = providers.map((provider) => ({
    connected: connections.some((conn) => conn.provider === provider.provider),
    provider: provider.provider,
    name: provider.name,
  }));

  const [openProvider, setOpenProvider] = useState<"vrm" | "ixon" | null>(null);
  const [showTwoFactorInput, setShowTwoFactorInput] = useState(false);

  const connectMutation = useMutation({
    onError: (error) => {
      return toast.error(error.message, { position: "bottom-center" });
    },
    onSuccess: () => {
      toast.success("Connection created successfully", {
        position: "bottom-center",
      });
    },
    mutationFn: async ({
      password,
      username,
      twoFactorCode,
      provider,
    }: {
      provider: "vrm" | "ixon";
      username: string;
      password: string;
      twoFactorCode: string;
    }) => {
      if (!owner) {
        return;
      }

      if (provider === "vrm") {
        const results = await loginToVrm({
          httpClient: new HttpClient(`${api.baseUrl}/vrm`),
          username,
          password,
          twoFactorCode,
        });

        if (!results.ok || !results.json) {
          throw new Error(results.error?.errors);
        }
        if (results.json.verification_sent) {
          setShowTwoFactorInput(true);
          throw new Error("Fill your 2FA code and try again.");
        }

        const token = await createAccessToken({
          httpClient: new HttpClient(`${api.baseUrl}/vrm`),
          userId: results.json.idUser,
          token: results.json.token,
        });

        if (!token.ok || !token.json) {
          throw new Error(JSON.stringify(token.error));
        }

        await supabase.from("connections").insert([
          {
            expires_at: new Date("2300-01-01").toISOString(),
            provider: "vrm",
            token: token.json,
            company_id: owner.company_id,
          },
        ]);

        queryClient.invalidateQueries({ queryKey: ["connections"] });
      }

      if (provider === "ixon") {
        const results = await loginToIxon({
          username,
          password,
          twoFactorCode,
        });
        if (!results.ok || !results.json) {
          throw new Error(results.error?.data[0].message);
        }

        await supabase.from("connections").insert([
          {
            expires_at: results.json.data.expiresOn,
            provider: "ixon",
            token: results.json.data.secretId,
            company_id: owner.company_id,
            additional_info: { tokenId: results.json.data.publicId },
          },
        ]);

        queryClient.invalidateQueries({ queryKey: ["connections"] });
      }
      setOpenProvider(null);
    },
  });

  const disconnectMutation = useMutation({
    onError: (error) => {
      return toast.error(error.message, { position: "bottom-center" });
    },
    onSuccess: () => {
      toast.success("Connection removed successfully", {
        position: "bottom-center",
      });
    },
    mutationFn: async (provider: "vrm" | "ixon") => {
      await supabase.from("connections").delete().eq("provider", provider);

      queryClient.invalidateQueries({ queryKey: ["connections"] });

      setOpenProvider(null);
    },
  });

  return (
    <div className="flex flex-col gap-2 py-4 w-full grow">
      {connectionsList.map((provider, index) => (
        <Provider
          key={index}
          showCredentials={openProvider === provider.provider}
          name={provider.name}
          error={connectMutation.error?.message ?? null}
          connected={provider.connected}
          showTwoFactorInput={
            showTwoFactorInput || provider.provider === "ixon"
          }
          onConnect={({ username, password, twoFactorCode }) =>
            connectMutation.mutate({
              provider: provider.provider,
              username,
              password,
              twoFactorCode,
            })
          }
          onToggle={async (on) => {
            if (!on) {
              disconnectMutation.mutate(provider.provider);
            } else {
              setOpenProvider(provider.provider);
            }
          }}
        />
      ))}
    </div>
  );
};

export default ProviderList;
