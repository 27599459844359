import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { Controller, useForm } from "react-hook-form";
import { getAllDashboardsForUser } from "@/services/queries/dashboards/getAllDashboards";
import { useEffect, useMemo } from "react";
import toast from "react-hot-toast";
import { createAlert } from "@/services/queries/alerts/createAlert";
import DropdownList from "../common/DropdownList";
import { Button } from "../ui/button";
import { FaChevronRight } from "react-icons/fa6";
import { profileService } from "@/services/queries/profile/profileService";
import { zodResolver } from "@hookform/resolvers/zod";
import { AlertsFormSchema, AlertsFormValues } from "./AlertsForm.schema";

const AlertsForm = () => {
  const queryClient = useQueryClient();

  const profileQueryResult = useQuery({
    queryKey: ["profiles"],
    queryFn: profileService.getProfilesForCompany,
  });

  const defaultValues = useMemo(
    () => ({
      dashboard: "0",
      max: null,
      min: null,
      name: "",
      profile: profileQueryResult.data?.[0]?.id ?? "",
      variable: "",
      media: "email" as "email" | "push",
    }),
    [profileQueryResult.data]
  );

  const {
    handleSubmit,
    register,
    getValues,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm<AlertsFormValues>({
    resolver: zodResolver(AlertsFormSchema),
    defaultValues,
  });

  const dashboardWatch = watch("dashboard");

  const dashboardsQueryResult = useQuery({
    queryKey: ["dashboards", getValues().profile],
    queryFn: () => getAllDashboardsForUser(getValues().profile),
    enabled: !!getValues().profile,
  });

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      const newProfile = value.profile;
      if (name === "profile" && newProfile) {
        reset({
          ...defaultValues,
          profile: newProfile,
          name: getValues().name,
        });
        queryClient.fetchQuery({
          queryKey: ["dashboards", newProfile],
          queryFn: () => getAllDashboardsForUser(newProfile),
        });
      }
    });
    return () => subscription.unsubscribe();
  }, [defaultValues, getValues, queryClient, reset, watch]);

  const mutation = useMutation({
    mutationFn: async (data: AlertsFormValues) => {
      if (!data.dashboard || !data.variable || (!data.min && !data.max)) {
        toast.error(
          "Make sure you select a user, dashboard and variable to receive an alert for",
          {
            position: "bottom-center",
          }
        );

        return;
      }
      const createdAlert = await createAlert({
        dashboardId: parseInt(data.dashboard),
        userId: data.profile,
        name: data.name,
        min: data.min ?? null,
        max: data.max ?? null,
        variable: data.variable,
        media: [data.media as "email" | "push"],
      });

      reset();

      return createdAlert.data;
    },
    onError: (error) => {
      toast.error(error.message, { position: "bottom-center" });
    },
    onSuccess: (data) => {
      queryClient.setQueryData<Awaited<ReturnType<typeof createAlert>>["data"]>(
        ["alerts"],
        (oldData) => {
          if (!data) {
            return oldData ?? [];
          }

          return data;
        }
      );

      toast.success("Alert created!", { position: "bottom-center" });
    },
  });

  const submit = handleSubmit((data) => mutation.mutate(data));

  return (
    <form onSubmit={submit} className="flex flex-col">
      <div className="py-4 flex flex-col justify-between gap-2">
        <Label htmlFor="name">Alert name</Label>
        <Input {...register("name")} type="text" id="name" />
        {errors.name && (
          <p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
        )}
      </div>
      <div className="py-4 grid grid-cols-1 md:grid-cols-3 gap-3">
        <div>
          <Controller
            name="profile"
            control={control}
            render={({ field }) => (
              <DropdownList
                htmlFor="profile"
                label="Select a user:"
                onChange={field.onChange}
                options={
                  profileQueryResult.data?.map((profile) => ({
                    label: profile.email,
                    value: profile.id.toString(),
                  })) ?? []
                }
              />
            )}
          />
          {errors.profile && (
            <p className="text-red-500 text-sm mt-1">
              {errors.profile.message}
            </p>
          )}
        </div>
        <div>
          <Controller
            name="dashboard"
            control={control}
            render={({ field }) => (
              <DropdownList
                htmlFor="dashboard"
                label="Select a dashboard:"
                onChange={field.onChange}
                options={
                  dashboardsQueryResult.data?.map((dashboard) => ({
                    label: dashboard.name,
                    value: dashboard.id.toString(),
                  })) ?? []
                }
              />
            )}
          />
          {errors.dashboard && (
            <p className="text-red-500 text-sm mt-1">
              {errors.dashboard.message}
            </p>
          )}
        </div>
        <div>
          <Controller
            name="variable"
            control={control}
            render={({ field }) => (
              <DropdownList
                htmlFor="variable"
                label="Select a variable:"
                onChange={field.onChange}
                options={
                  Object.values(
                    dashboardsQueryResult.data?.find(
                      (dashboard) => dashboard.id.toString() === dashboardWatch
                    )?.widgets ?? []
                  ).map((widget) => ({
                    label: widget.code,
                    value: widget.code,
                  })) ?? []
                }
              />
            )}
          />
          {errors.variable && (
            <p className="text-red-500 text-sm mt-1">
              {errors.variable.message}
            </p>
          )}
        </div>
      </div>
      <div className="py-4 grid grid-cols-1 md:grid-cols-3 gap-3">
        <Controller
          name="media"
          control={control}
          render={({ field }) => (
            <DropdownList
              htmlFor="media"
              label="Select the media to receive the alert:"
              onChange={field.onChange}
              options={[
                { label: "Email", value: "email" },
                { label: "Push notification", value: "push" },
              ]}
            />
          )}
        />
        <div className="flex flex-col gap-2">
          <Label htmlFor="min">Min</Label>
          <Input {...register("min")} type="number" id="min" />
          {errors.min && (
            <p className="text-red-500 text-sm mt-1">{errors.min.message}</p>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <Label htmlFor="max">Max</Label>
          <Input {...register("max")} type="number" id="max" />
        </div>
      </div>
      <div className="flex justify-center p-8 grow flex items-center justify-end flex-col">
        <Button type="submit" variant="default" size="lg">
          <p className="text-md">Save</p>
          <FaChevronRight size={20} color="white" />
        </Button>
      </div>
    </form>
  );
};

export default AlertsForm;
