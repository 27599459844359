import { Widget } from "@battery-monitor/types";

interface Alert {
  id: number;
  name: string;
  variable: Widget;
  min?: number;
  max?: number;
  email: string;
}

interface AlertTableProps {
  alerts: Alert[];
  onRemove: (id: number) => void;
}

const mapVariableName = (variable: Widget) => {
  switch (variable) {
    case "batterySoc":
      return "Battery SOC";
    case "inputPower":
      return "Input power";
    case "outputPower":
      return "Output power";
    case "inputVoltage":
      return "Output power";
    default:
      return "Unknown";
  }
};

const AlertTable = ({ alerts, onRemove }: AlertTableProps) => {
  return (
    <div>
      {alerts.length === 0 && (
        <p className="text-black">No alerts created yet.</p>
      )}
      {alerts.map((alert) => (
        <div
          key={alert.id}
          className="bg-white shadow-md border border-gray-400 rounded p-4 mb-4 flex flex-col md:flex-row justify-between md:items-center"
        >
          <div className="flex flex-col gap-1 pb-5 md:pb-0">
            <p className="text-lg text-black font-semibold">{alert.name}</p>
            <p className="text-sm  text-black">
              Variable: {mapVariableName(alert.variable)}
            </p>
            <p className="text-sm  text-black">
              Min: {alert.min ?? "Not set"} - Max: {alert.max ?? "Not set"}
            </p>
            <p className="text-sm  text-black">User: {alert.email}</p>
          </div>
          <button
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
            onClick={() => onRemove(alert.id)}
          >
            Remove
          </button>
        </div>
      ))}
    </div>
  );
};

export default AlertTable;
