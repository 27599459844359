import type { VFC } from "react";

interface InstallationsItemProps {
  id: string;
  name: string;
  onClick: (id: string) => void;
}

const InstallationsItem: VFC<InstallationsItemProps> = ({
  id,
  name,
  onClick,
}) => {
  return (
    <li>
      <p
        className="p-4 my-6 bg-gray-400 items-end rounded-lg hover:bg-gray-800"
        onClick={() => onClick(id)}
      >
        {name}
      </p>
    </li>
  );
};

export default InstallationsItem;
