import * as Sentry from "@sentry/react";

const sentryConfig = {
  enabled: import.meta.env.DEV === false,
  dsn: "https://42cac396c2666512adc779190486d019@o4505499967684608.ingest.sentry.io/4506485069709312",
  integrations: [
    Sentry.browserTracingIntegration({
      tracePropagationTargets: [
        "localhost",
        /^https:\/\/battery-monitor\.fly\.dev\/api\/v1/,
      ],
    }),
  ],
  tracesSampleRate: 0.1, //  Capture 100% of the transactions
} satisfies Sentry.BrowserOptions;

export const useSentry = () => {
  Sentry.init(sentryConfig);
};
