import { Route } from "@tanstack/react-router";
import { adminRoute } from "./root";
import PageContainer from "@/components/common/PageContainer";
import AddUser from "@/components/users/AddUser";

const AdminSettingsUser = () => {
  return (
    <PageContainer className="p-50 justify-center items-center">
      <AddUser />
    </PageContainer>
  );
};

export const adminSettingsUserRoute = new Route({
  getParentRoute: () => adminRoute,
  path: "/user",
  component: AdminSettingsUser,
});
