import { IDataClient } from "../client.interface";
import { dataClient } from "../dataClient";

export class ProfileService {
  constructor(private dataClient: IDataClient) {}

  public get builder() {
    return this.dataClient.client.from("profiles");
  }

  public getCompanyId = async (userId: string) => {
    const company_id = await this.builder
      .select("company_id")
      .eq("id", userId ?? "")
      .single();

    if (company_id.error) {
      throw new Error(company_id.error.message);
    }

    return company_id.data.company_id;
  };

  public getProfile = async (userId: string) => {
    const profile = await this.builder
      .select("*")
      .eq("id", userId ?? "")
      .single();

    if (profile.error) {
      throw new Error(profile.error.message);
    }

    return profile.data;
  };

  public getProfilesForCompany = async () => {
    const profiles = await this.builder.select("*");
    if (profiles.error) {
      throw new Error(profiles.error.message);
    }

    return profiles.data;
  };
}

export const profileService = new ProfileService(dataClient);
