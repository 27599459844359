import { signOut } from "@/services/auth/signOut";
import { getOwner } from "@/services/queries/owners/getOwner";
import useStore from "@/store/store";
import { useQuery } from "@tanstack/react-query";
import { Link, useNavigate } from "@tanstack/react-router";
import classNames from "classnames";
import { useEffect, useState } from "react";

const Sidebar = () => {
  const isBarOpen = useStore((state) => state.sideBarOpen);

  const toggleSideBarOpen = useStore((state) => state.toggleSideBarOpen);

  const { user } = useStore();

  const navigate = useNavigate();

  const owner = useQuery({
    queryKey: ["owner"],
    queryFn: getOwner,
  });

  const isAdmin = !!owner.data?.id;

  const [width, setWidth] = useState("w-0");
  const [opacity, setOpacity] = useState("bg-gray-200/0");

  useEffect(() => {
    if (!isBarOpen || !user) {
      setWidth("w-0");
      setOpacity("bg-gray-200/0");
    } else {
      setOpacity("bg-gray-200/75");
      setWidth("w-[200px]");
    }
  }, [isBarOpen, user]);

  if (!isBarOpen || !user) {
    return null;
  }

  return (
    <div
      className={classNames(
        "absolute top-[90px] flex h-[calc(100%-90px)] w-full transition-opacity duration-1000 ease-in-out",
        opacity
      )}
      onClick={toggleSideBarOpen}
    >
      <div
        className={classNames(
          "bg-white flex-col flex h-full z-10 border-0 transition-all duration-200 ease-in-out",
          width
        )}
        onClick={(event) => event.stopPropagation()}
      >
        <div className="overflow-x-hidden py-4 whitespace-nowrap">
          {isAdmin && (
            <div className="space-y-2">
              <p className="px-4 font-semibold text-lg tracking-widest text-gray-400 uppercase">
                Admin
              </p>
              <div className="mt-4 bg-top bg-cover space-y-1">
                <Link
                  onClick={toggleSideBarOpen}
                  to="/admin/connections"
                  className="font-medium text-md items-center rounded-lg text-gray-900 px-4 py-2.5 block
                          transition-all duration-200 hover:bg-gray-200 group cursor-pointer"
                >
                  <span>Connections</span>
                </Link>
              </div>
              <div className="bg-top bg-cover">
                <Link
                  onClick={toggleSideBarOpen}
                  to="/admin/user"
                  className="font-medium text-md items-center rounded-lg text-gray-900 px-4 py-1 block
                          transition-all duration-200 hover:bg-gray-200 group cursor-pointer"
                >
                  <span>Users</span>
                </Link>
              </div>
              <div className="bg-top bg-cover space-y-1">
                <Link
                  onClick={toggleSideBarOpen}
                  to="/admin/dashboard"
                  className="font-medium text-md items-center rounded-lg text-gray-900 px-4 py-2.5 block
                          transition-all duration-200 hover:bg-gray-200 group cursor-pointer"
                >
                  <span>Add dashboard</span>
                </Link>
              </div>
            </div>
          )}
          <div className="space-y-2 my-10">
            <p className="px-4 font-semibold text-lg tracking-widest text-gray-400 uppercase">
              Dashboards
            </p>
            <div className="bg-top bg-cover space-y-1">
              <Link
                onClick={toggleSideBarOpen}
                to="/dashboard"
                className="font-medium text-md items-center rounded-lg text-gray-900 px-4 py-2.5 block
                          transition-all duration-200 hover:bg-gray-200 group cursor-pointer"
              >
                <span>Overview</span>
              </Link>
            </div>
          </div>
          <div className="space-y-2 my-10">
            <p className="px-4 font-semibold text-lg tracking-widest text-gray-400 uppercase">
              Alerts
            </p>
            <div className="bg-top bg-cover space-y-1">
              <Link
                onClick={toggleSideBarOpen}
                to="/alerts"
                className="font-medium text-md items-center rounded-lg text-gray-900 px-4 py-2.5 block
                          transition-all duration-200 hover:bg-gray-200 group cursor-pointer"
              >
                <span>Overview</span>
              </Link>
            </div>
          </div>
          <div className="mt-12 pb-4">
            <div className="bg-top bg-cover space-y-1 flex items-center ">
              <button
                className="grow"
                onClick={async () => {
                  await signOut();
                  navigate({ to: "/" });
                  toggleSideBarOpen();
                }}
              >
                <p
                  className="font-medium text-md items-center rounded-lg text-gray-900 px-4 py-2.5 flex
                        transition-all duration-200 hover:bg-gray-200 group cursor-pointer"
                >
                  Log out
                </p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Sidebar;
