import { api } from "@/config/api";
import {
  IxonProviderClient,
  VrmProviderClient,
} from "@battery-monitor/providers";
import { Connection } from "@battery-monitor/types";

export const getAllInstallations = async (connections: Connection[]) => {
  const installations = await Promise.all(
    connections.map(async (connection) => {
      if (connection.provider === "vrm") {
        const client = new VrmProviderClient(
          connection.token,
          `${api.baseUrl}/vrm`
        );

        const vrmInstallations = await client.getInstallations();
        return {
          name: connection.provider,
          installations: vrmInstallations,
        } satisfies {
          name: "ixon" | "vrm";
          installations: {
            name: string;
            id: string;
          }[];
        };
      }

      const client = new IxonProviderClient(connection.token);
      const ixonInstallations = await client.getInstallations();

      return {
        name: "ixon",
        installations: ixonInstallations,
      } satisfies {
        name: "ixon" | "vrm";
        installations: {
          name: string;
          id: string;
        }[];
      };
    }) ?? []
  );

  return installations;
};
