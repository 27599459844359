import { WidgetCollection } from "@battery-monitor/types";

export const widgetCollection = {
  outputPower: {
    description: "Output Power",
    code: "outputPower",
    components: [
      {
        description: "Output Power 1",
        code: "OP1",
      },
      {
        description: "Output Power 2",
        code: "OP2",
      },
      {
        description: "Output Power 3",
        code: "OP3",
      },
    ],
  },
  inputPower: {
    description: "Input Power",
    code: "inputPower",
    components: [
      {
        description: "Input Power 1",
        code: "IP1",
      },
      {
        description: "Input Power 2",
        code: "IP2",
      },
      {
        description: "Input Power 3",
        code: "IP3",
      },
    ],
  },
  batterySoc: {
    description: "Battery SOC (State of Charge)",
    code: "batterySoc",
    components: [
      {
        description: "SOC",
        code: "SOC",
      },
    ],
  },
  inputVoltage: {
    description: "Input Voltage",
    code: "inputVoltage",
    components: [
      {
        description: "Input Voltage 1",
        code: "IV1",
      },
      {
        description: "Input Voltage 2",
        code: "IV2",
      },
      {
        description: "Input Voltage 3",
        code: "IV3",
      },
    ],
  },
} satisfies WidgetCollection;
