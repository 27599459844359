import { supabase } from "@/config/supabase";

export const getAlerts = async () => {
  const alerts = await supabase.from("alerts").select(`
      id,
      rule,
      user_id,
      name,
      enabled,
      dashboards (
        provider,
        config
      ),
      profiles (
        email
      )
    `);

  if (alerts.error) {
    throw new Error(alerts.error.message);
  }

  return alerts.data;
};
