import { ChartDataCollection } from "@battery-monitor/types";
import LineChart from "../charts/LineChart";
import { DashboardSettings } from "./DashboardSettings.schema";

interface DashboardProps {
  data: ChartDataCollection;
  settings: DashboardSettings;
}

const chartMap: {
  [key: string]: {
    unit: string;
    name: string;
  };
} = {
  inputPower: {
    unit: "kW",
    name: "Input Power (kW)",
  },
  outputPower: {
    unit: "kW",
    name: "Output Power (kW)",
  },
  batterySoc: {
    unit: "%",
    name: "Battery State of Charge (%)",
  },
  inputVoltage: {
    unit: "V",
    name: "Input Voltage (V)",
  },
};

const Dashboard = ({ data, settings }: DashboardProps) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 bg-white pr-5 grow">
      {Object.entries(data).map(([key, value]) => (
        <LineChart
          startFromZero={settings.startFromZero}
          key={key}
          data={value}
          name={chartMap[key].name}
          unit={chartMap[key].unit}
        />
      ))}
    </div>
  );
};

export default Dashboard;
