import { z } from "zod";

const media = ["email", "push"] as const;

const MediaSchema = z.enum(media);

export const AlertsFormSchema = z
  .object({
    dashboard: z.string().min(1, "You must select a dashboard"),
    max: z.coerce
      .number()
      .nullish()
      .transform((x) => (x ? x : null)),
    min: z.coerce
      .number()
      .nullish()
      .transform((x) => (x ? x : null)),
    name: z.string().min(3, "Name must be at least 3 characters long"),
    profile: z.string().min(1, "You must select a user"),
    variable: z.string().min(1, "You must select a variable"),
    media: MediaSchema,
  })
  .refine((data) => data.min !== null || data.max !== null, {
    message: "You must select a min or max value",
    path: ["min"],
  });

export type AlertsFormValues = z.infer<typeof AlertsFormSchema>;
