import { useIosInstallModal } from "@/hooks/usePushManager";
import { useState } from "react";
import Popup from "reactjs-popup";
import { IoShareOutline } from "react-icons/io5";

const IosInstallModal = () => {
  const shouldShowModalResponse = useIosInstallModal();
  const [open, setOpen] = useState(shouldShowModalResponse);
  const closeModal = () => setOpen(false);

  return (
    <Popup
      open={open}
      closeOnDocumentClick
      onClose={closeModal}
      contentStyle={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      overlayStyle={{
        backgroundColor: "rgba(180, 180, 180, 0.5)",
      }}
    >
      <div className="modal w-3/4 md:w-1/2 border-2 border-gray-300 rounded-xl bg-white p-10 opacity-100 flex flex-col">
        <h2 className="self-center font-bold text-2xl text-black pb-8">
          Welcome!
        </h2>
        <p className="text-black pb-4">
          For the best experience on your iPhone, install this application on
          your home screen.
        </p>
        <p className="text-black pb-4 font-semibold">
          How can I install this app?
        </p>
        <ul className="list-disc">
          <li className="text-black pb-2">
            To install the app, tap the “share” icon (
            <IoShareOutline className="inline" color="black" size={20} />) in
            the top right of your browser if using Google Chrome, or bottom
            center if you are using Safari.
          </li>
          <li className="text-black pb-2">Next, scroll down</li>
          <li className="text-black pb-2">
            Lastly, select "Add to home screen"
          </li>
        </ul>
      </div>
    </Popup>
  );
};

export default IosInstallModal;
