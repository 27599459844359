import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  IProviderAuthentication,
  ProvderAuthenticationSchema,
} from "./Provider.schema";

interface ProviderProps {
  name: string;
  connected: boolean;
  showTwoFactorInput: boolean;
  onToggle: (on: boolean) => void;
  showCredentials: boolean;
  error: string | null;
  onConnect: (form: IProviderAuthentication) => void;
}

const Provider = ({
  name,
  connected,
  onToggle,
  showCredentials,
  showTwoFactorInput,
  error,
  onConnect,
}: ProviderProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IProviderAuthentication>({
    defaultValues: { username: "", password: "", twoFactorCode: "" },
    resolver: zodResolver(ProvderAuthenticationSchema),
  });

  return (
    <div className="bg-white p-4 border rounded-lg shadow-md mb-4 sm:max-md:max-w-s">
      <div className="flex items-center justify-between">
        <h2 className="text-xl text-black align-middle font-semibold mb-2">
          {name}
        </h2>
        <button
          className={`px-4 py-2 rounded-full ${
            connected ? "bg-red-500" : "bg-green-500"
          } text-white hover:bg-opacity-80 transition duration-300`}
          onClick={() => onToggle(!connected)}
        >
          {connected ? "Disconnect" : "Connect"}
        </button>
      </div>
      {showCredentials && (
        <form className="mt-4 flex flex-col" onSubmit={handleSubmit(onConnect)}>
          <div className="mb-4 flex flex-col gap-2">
            <Label htmlFor="username">Username</Label>
            <Input {...register("username")} type="text" />
            {errors.username && <p>{errors.username.message}</p>}
          </div>
          <div className="mb-4 flex flex-col gap-2">
            <Label htmlFor="password">Password</Label>
            <Input type="password" {...register("password")} />
            {errors.password && <p>{errors.password.message}</p>}
          </div>
          {showTwoFactorInput && (
            <div className="mb-4 flex flex-col gap-2">
              <Label htmlFor="two-factor">
                {name === "IXON"
                  ? "If you have enabled 2FA, add your code"
                  : "Two factor authentication code"}
              </Label>
              <Input type="text" {...register("twoFactorCode")} />
            </div>
          )}
          <div className="flex flex-col md:flex-row grow items-center justify-between">
            <p className="text-red-500 font-semibold whitespace-normal">
              {error}
            </p>
            <button
              type="submit"
              className="px-4 py-2 rounded-full bg-blue-500 text-white hover:bg-opacity-80 transition duration-300"
            >
              Submit
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default Provider;
