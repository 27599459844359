import { Outlet } from "@tanstack/react-router";
import "../globals.css";
import Header from "@/components/common/Header";
import Sidebar from "@/components/common/Sidebar";
import useStore from "@/store/store";

const Root = () => {
  const { user } = useStore();

  return (
    <div className="h-screen flex flex-col overflow-hidden">
      {!!user && <Header />}
      <div className="grow flex overflow-auto">
        <Outlet />
      </div>
      <Sidebar />
    </div>
  );
};

export default Root;
